// src/components/Map.js

import React, { useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  InfoWindow,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import { LocationContext } from '../services/LocationContext';
import { Box, Typography, Rating, Chip } from '@mui/material';

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

const AdvancedMarkerWithRef = (props) => {
  const { children, onClick, onMouseOver, onMouseOut, ...advancedMarkerProps } = props;
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
    <AdvancedMarker
      ref={markerRef}
      onClick={() => onClick && onClick(marker)}
      onMouseOver={() => onMouseOver && onMouseOver(marker)}
      onMouseOut={() => onMouseOut && onMouseOut(marker)}
      {...advancedMarkerProps}
    >
      {children}
    </AdvancedMarker>
  );
};

const MapComponent = ({ businesses, center, zoom, onMarkerClick }) => {
  const navigate = useNavigate();
  const { latitude, longitude } = useContext(LocationContext);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const mapRef = useRef(null);

  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API;

  const defaultCenter = {
    lat: 42.2626,
    lng: -71.8023,
  };

  const defaultZoom = 12;

  const mapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: true,
    gestureHandling: 'greedy',
    disableDefaultUI: true,
  };

  const handleMarkerClick = (business, marker) => {
    setSelectedBusiness(business);
    setSelectedMarker(marker);
    if (onMarkerClick) {
      onMarkerClick(business.id);
    }

    if (mapRef.current && marker?.position) {
      // Move the map center slightly south (decrease lat) to provide space above the marker
      const latOffset = 0.01; // Increase this if you need more space
      const newCenter = {
        lat: marker.position.lat() - latOffset,
        lng: marker.position.lng(),
      };
      mapRef.current.panTo(newCenter);
    }
  };

  const handleInfoWindowClick = (businessId) => {
    navigate(`/business/${businessId}`);
  };

  const getDefaultCenter = () => {
    if (center && center.lat && center.lng) {
      return center;
    }
    if (latitude && longitude) {
      return {
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      };
    }
    return defaultCenter;
  };

  const getImageSrc = (business) => {
    return (
      business.heroImage ||
      business.imageUrl ||
      business.image_url ||
      'https://via.placeholder.com/300x300'
    );
  };

  return (
    <div style={mapContainerStyle}>
      {GOOGLE_MAPS_API_KEY ? (
        <APIProvider
          apiKey={GOOGLE_MAPS_API_KEY}
          libraries={['places', 'marker']}
          scriptOptions={{
            loading: 'async',
            nonce: undefined,
            preventLoad: false,
          }}
        >
          <Map
            mapId="976e6c29bc5d03d3"
            center={getDefaultCenter()}
            zoom={zoom || defaultZoom}
            options={mapOptions}
            style={mapContainerStyle}
            onClick={() => {
              setSelectedBusiness(null);
              setSelectedMarker(null);
            }}
            onLoad={(map) => {
              mapRef.current = map;
            }}
          >
            {businesses.map((business) => (
              <React.Fragment key={business.id}>
                <AdvancedMarkerWithRef
                  position={business.location}
                  title={business.name}
                  onClick={(marker) => handleMarkerClick(business, marker)}
                >
                  <Pin
                    scale={
                      selectedBusiness?.id === business.id
                        ? 1.4
                        : 1
                    }
                    background={
                      selectedBusiness?.id === business.id ? '#2E7D32' : '#D32F2F'
                    }
                    borderColor={
                      selectedBusiness?.id === business.id ? '#1B5E20' : '#C62828'
                    }
                    glyphColor="#FFFFFF"
                  />
                </AdvancedMarkerWithRef>
              </React.Fragment>
            ))}

            {selectedBusiness && selectedMarker && (
              <InfoWindow
                anchor={selectedMarker}
                onCloseClick={() => {
                  setSelectedBusiness(null);
                  setSelectedMarker(null);
                }}
                // Use the pixelOffset to shift the InfoWindow downward
                options={{
                  pixelOffset: new window.google.maps.Size(0, -30)
                }}
              >
                <Box
                  onClick={() => handleInfoWindowClick(selectedBusiness.id)}
                  sx={{
                    padding: '0px 0px',
                    maxWidth: 300,
                    cursor: 'pointer',
                    '&:hover': {
                      opacity: 0.9,
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '150px',
                      overflow: 'hidden',
                      borderRadius: '8px',
                      mb: 2,
                    }}
                  >
                    <Box
                      component="img"
                      src={getImageSrc(selectedBusiness)}
                      alt={`Image of ${selectedBusiness.name}`}
                      sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </Box>

                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: '1.2rem',
                      fontWeight: 600,
                      mb: 1,
                    }}
                  >
                    {selectedBusiness.name}
                  </Typography>

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mb: 1,
                    }}
                  >
                    <Rating
                      value={selectedBusiness.rating}
                      precision={0.1}
                      readOnly
                      size="small"
                    />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ ml: 0.5 }}
                    >
                      {selectedBusiness.rating?.toFixed(1)}
                    </Typography>
                  </Box>

                  <Box
                    sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 1 }}
                  >
                    {selectedBusiness.price && (
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 600,
                          marginRight: 1,
                          color: 'text.secondary',
                        }}
                      >
                        {selectedBusiness.price}
                      </Typography>
                    )}
                    {selectedBusiness.Tags?.slice(0, 3).map((tag, index) => (
                      <Chip
                        key={`${tag.id}-${index}`}
                        label={tag.name}
                        size="small"
                        sx={{
                          fontSize: '0.8rem',
                          maxWidth: '100px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      />
                    ))}
                  </Box>

                  {selectedBusiness.description && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {selectedBusiness.description}
                    </Typography>
                  )}
                </Box>
              </InfoWindow>
            )}
          </Map>
        </APIProvider>
      ) : (
        <p>Google Maps API key is missing or invalid.</p>
      )}
    </div>
  );
};

export default React.memo(MapComponent);
