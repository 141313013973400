// src/components/BusinessCarousel.js

import React, { useRef, useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import VerticalBusinessCard from './VerticalBusinessCard';

const BusinessCarousel = ({ title, businesses }) => {
  const swiperRef = useRef(null);

  // State variables to manage navigation button visibility
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  // Handler for slide change
  const handleSlideChange = (swiper) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  return (
    <Box sx={{ py: 6, px: 2 }}>
      <Box sx={{ maxWidth: '1200px', margin: '0 auto', position: 'relative' }}>
        {/* Title */}
        <Typography variant="h3" sx={{ mb: 2, textAlign: 'left', fontWeight: 400 }}>
          {title}
        </Typography>

        <Box sx={{ position: 'relative' }}>
          {/* Navigation Buttons */}
          {!isBeginning && (
            <IconButton
              aria-label="previous slides"
              onClick={() => swiperRef.current?.slidePrev()}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '-50px', // Changed from '10px'
                transform: 'translateY(-50%)',
                backgroundColor: 'primary.main',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                },
                zIndex: 10,
                width: 45,
                height: 45,
                boxShadow: 3,
              }}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
          )}

          {!isEnd && (
            <IconButton
              aria-label="next slides"
              onClick={() => swiperRef.current?.slideNext()}
              sx={{
                position: 'absolute',
                top: '50%',
                right: '-50px', // Changed from '-55px' to match left button
                transform: 'translateY(-50%)',
                backgroundColor: 'primary.main',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                },
                zIndex: 10,
                width: 45,
                height: 45,
                boxShadow: 3,
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          )}

          {/* Check if businesses array is defined and not empty */}
          {businesses && businesses.length > 0 ? (
            <>
              {/* Swiper Carousel */}
              <Swiper
                modules={[Navigation]}
                slidesPerView={3}
                centeredSlides={false}
                spaceBetween={24}
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;
                }}
                onSlideChange={handleSlideChange}
                loop={false}
                style={{ width: '100%' }}
              >
                {businesses.map((business) => (
                  <SwiperSlide key={business.id} style={{ width: 'auto' }}>
                    <VerticalBusinessCard business={business} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </>
          ) : (
            // Display message when no businesses are available
            <Typography variant="h6">
              No businesses available
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BusinessCarousel;
