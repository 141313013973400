// src/pages/Explore.js

import React, { useState, useEffect, useContext, useCallback } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MapComponent from '../components/Map';
import Filters from '../components/Filters';
import VerticalBusinessCard from '../components/VerticalBusinessCard';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import '../styles/Explore.css';
import {
  Box,
  Button,
  Drawer,
  useMediaQuery,
  Typography,
  CircularProgress,
  Fade,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useTheme } from '@mui/material/styles';
import { useParams, useLocation } from 'react-router-dom';
import { LocationContext } from '../services/LocationContext';
import queryString from 'query-string';
import { AuthContext } from '../components/AuthContext';

const Explore = () => {
  // src/constants/categories.js

  const categories = [
    { id: 1, name: 'Health & Wellness' },
    { id: 2, name: 'Retail' },
    { id: 3, name: 'Entertainment & Recreation' },
    { id: 4, name: 'Services' },
    { id: 5, name: 'Food & Drink' },
    { id: 6, name: 'Other' },
    { id: 7, name: 'Community & Government' },
  ];

  const { isAuthenticated, userRole, userUUID } = useContext(AuthContext);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { category } = useParams();
  const location = useLocation();
  const { keywords, city: queryCity, state: queryState } = queryString.parse(
    location.search
  );

  const {
    city,
    region,
    latitude,
    longitude,
    loading: locationLoading,
  } = useContext(LocationContext);

  const effectiveCity = queryCity || city;
  const effectiveRegion = queryState || region;

  const [businesses, setBusinesses] = useState([]);
  const [filteredBusinesses, setFilteredBusinesses] = useState([]);
  const [totalBusinesses, setTotalBusinesses] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;

  const [filters, setFilters] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const [availableTags, setAvailableTags] = useState([]); // State for available tags
  const [selectedBusinessId, setSelectedBusinessId] = useState(null);

  // Add initialFilters state
  const [initialFilters, setInitialFilters] = useState({});

  // Add effect to set initial category
  useEffect(() => {
    if (category) {
      setInitialFilters(prev => ({
        ...prev,
        category: category
      }));
    }
  }, [category]);

  // Debounced loading indicator
  useEffect(() => {
    let timer;
    if (isLoading) {
      timer = setTimeout(() => {
        setShowLoading(true);
      }, 300); // 300ms delay before showing loading indicator
    } else {
      setShowLoading(false);
    }
    return () => clearTimeout(timer);
  }, [isLoading]);

  // Toggle Drawer
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  // Handler for filter changes
  const handleFilterChange = useCallback((newFilters) => {
    setFilters(newFilters);
    // Reset currentPage to 1 when filters change
    setCurrentPage(1);
  }, []);

  // Function to apply filters to the current list of businesses
  const applyFiltersToBusinesses = (filters, businessesList) => {
    let filtered = businessesList;
    if (filters.price) {
      filtered = filtered.filter((business) => business.price === filters.price);
    }
    if (filters.category) {
      filtered = filtered.filter(
        (business) => business.categoryId === parseInt(filters.category, 10)
      );
    }
    if (filters.rating) {
      filtered = filtered.filter((business) => business.rating >= filters.rating);
    }
    if (filters.openNow !== undefined) {
      filtered = filtered.filter((business) => business.openNow === filters.openNow);
    }
    // Remove tag filtering here as it's now handled by the backend
    setFilteredBusinesses(filtered);
  };

  // Fetch businesses based on category or keywords and location, including filters
  const fetchBusinesses = useCallback(
    async (page = 1) => {
      if (!effectiveCity || !effectiveRegion) {
        console.warn('City or region is missing.');
        setBusinesses([]);
        setTotalBusinesses(0);
        setAvailableTags([]); // Reset availableTags
        return;
      }

      if (isLoading) return;

      setIsLoading(true);

      try {
        const params = new URLSearchParams({
          city: effectiveCity,
          state: effectiveRegion,
          page: page,
          perPage: perPage,
        });

        // Add filters to query params
        if (filters.price) {
          // Map price to priceRange
          const priceMapping = { '$': '$', '$$': '$$', '$$$': '$$$', '$$$$': '$$$$' };
          const priceValue = priceMapping[filters.price];
          if (priceValue) params.set('priceRange', priceValue);
        }
        if (filters.category) params.set('category', filters.category);
        if (filters.tags && filters.tags.length > 0) {
          params.set('tags', filters.tags.join(',')); // Ensure tags are comma-separated
        }
        if (filters.rating) params.set('rating', filters.rating);
        if (filters.openNow !== undefined) params.set('openNow', filters.openNow);

        // Add distance filter
        if (filters.distance?.location && filters.distance?.radius) {
          params.set('distance', JSON.stringify({
            location: filters.distance.location,
            radius: filters.distance.radius
          }));
        }

        // Add keywords or category to params
        if (keywords) {
          params.set('keywords', keywords);
        }
        if (category) {
          params.set('category', category);
        }

        const endpoint = `/businesses/search?${params.toString()}`;

        console.log(
          'Fetching businesses with endpoint:',
          `${process.env.REACT_APP_API_URL}${endpoint}`
        );

        const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`);

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();

        console.log('Fetched data:', data);

        const newBusinesses = data.businesses
          .filter(
            (business) =>
              business.latitude &&
              business.longitude &&
              !isNaN(business.latitude) &&
              !isNaN(business.longitude)
          )
          .map((business) => ({
            ...business,
            location: {
              lat: parseFloat(business.latitude),
              lng: parseFloat(business.longitude),
            },
          }));

        // Replace the existing businesses with the new ones
        setBusinesses(newBusinesses);

        // Apply filters to the new list
        applyFiltersToBusinesses(filters, newBusinesses);

        setTotalBusinesses(data.total);

        // Set availableTags from the backend response
        setAvailableTags(data.availableTags || []);
      } catch (error) {
        console.error('Error fetching businesses:', error);
        setBusinesses([]);
        setFilteredBusinesses([]);
        setTotalBusinesses(0);
        setAvailableTags([]); // Reset availableTags on error
      } finally {
        setIsLoading(false);
      }
    },
    [
      effectiveCity,
      effectiveRegion,
      filters,
      keywords,
      category,
      isLoading,
      perPage,
      applyFiltersToBusinesses,
      filters.distance
    ]
  );

  // Fetch businesses when filters, page, or search parameters change
  useEffect(() => {
    if (!locationLoading) {
      fetchBusinesses(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    effectiveCity,
    effectiveRegion,
    locationLoading,
    filters,
    currentPage,
    keywords,
    category,
  ]);

  // Function to capitalize the first letter
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  // Determine the tagline based on whether it's a search or category view
  const defaultTagline = 'Explore the local favorites near you';
  let tagline = defaultTagline;

  if (keywords && effectiveCity && effectiveRegion) {
    tagline = (
      <>
        Search results for{' '}
        <Box component="span" sx={{ color: theme.palette.primary.main }}>
          "{capitalize(keywords)}"
        </Box>{' '}
        in{' '}
        <Box component="span" sx={{ color: theme.palette.primary.main }}>
          {capitalize(effectiveCity)}, {capitalize(effectiveRegion)}
        </Box>
      </>
    );
  } else if (category && effectiveCity && effectiveRegion) {
    // Find the category name based on the category ID
    const categoryId = parseInt(category, 10); // Ensure category is an integer
    const categoryObj = categories.find((c) => c.id === categoryId);
    const categoryName = categoryObj ? categoryObj.name : capitalize(category);

    tagline = (
      <>
        Top{' '}
        <Box component="span" sx={{ color: theme.palette.primary.main }}>
          {capitalize(categoryName)}
        </Box>{' '}
        in{' '}
        <Box component="span" sx={{ color: theme.palette.primary.main }}>
          {capitalize(effectiveCity)}, {capitalize(effectiveRegion)}
        </Box>
      </>
    );
  }

// Function to calculate the map view based on business locations or user location
const calculateMapView = useCallback((businessList) => {
  if (!businessList || businessList.length === 0) {
    // Use effective location instead of user location
    if (effectiveCity && effectiveRegion) {
      const matchingBusiness = businesses.find(b =>
        b.city === effectiveCity && b.state === effectiveRegion
      );
      if (matchingBusiness) {
        return {
          center: {
            lat: parseFloat(matchingBusiness.latitude), 
            lng: parseFloat(matchingBusiness.longitude)
          },
          zoom: 12,
        };
      }
    }
    // Fallback to user location or default
    if (latitude && longitude) {
      return {
        center: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
        zoom: 12,
      };
    }
    return {
      center: { lat: 37.7749, lng: -122.4194 }, // Default to San Francisco
      zoom: 12,
    };
  }

  const bounds = {
    north: -90,
    south: 90,
    east: -180,
    west: 180,
  };

  businessList.forEach((business) => {
    const { lat, lng } = business.location;
    bounds.north = Math.max(bounds.north, lat);
    bounds.south = Math.min(bounds.south, lat);
    bounds.east = Math.max(bounds.east, lng);
    bounds.west = Math.min(bounds.west, lng);
  });

  // Base center and zoom calculation
  const calculatedCenter = {
    lat: (bounds.north + bounds.south) / 2,
    lng: (bounds.east + bounds.west) / 2,
  };

  let initialZoom = 13; // Starting zoom level

  // Determine the latitude threshold that marks the top 1/2 (50%) of the vertical span
  // If total vertical span is (north - south), 50% of that is 0.5*(north - south).
  const verticalSpan = bounds.north - bounds.south;
  const thresholdLat = bounds.north - 0.6 * verticalSpan;

  // Check if any marker is in the top half of the bounding area
  const hasMarkerInTopSection = businessList.some((business) => {
    return business.location.lat >= thresholdLat;
  });

  // If there is a marker in the top half, reduce the zoom by 1.
  if (hasMarkerInTopSection) {
    initialZoom = Math.max(initialZoom - 1, 1); 
  }

  return {
    center: calculatedCenter,
    zoom: initialZoom,
  };
}, [effectiveCity, effectiveRegion, businesses, latitude, longitude]);


  const handleMarkerClick = (businessId) => {
    setSelectedBusinessId(businessId);
    // Find and scroll to the business card
    const businessCard = document.getElementById(`business-${businessId}`);
    if (businessCard) {
      businessCard.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <div
      className="Explore"
      style={{
        backgroundColor: theme.palette.background.paper,
        paddingTop: '40px',
      }}
    >
      <Header
        isAuthenticated={isAuthenticated}
        userRole={userRole}
        userUUID={userUUID}
        showSearchBar={true}
      />
      {/* Tagline */}
      <Box
        sx={{
          padding: '16px',
          backgroundColor: theme.palette.background.paper,
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h3"
          component="h2"
          sx={{
            textAlign: 'left',
            fontWeight: 400,
          }}
        >
          {tagline}
        </Typography>
      </Box>

      {/* Filters Section */}
      {!isSmallScreen ? (
        <div
          style={{
            padding: '16px',
            backgroundColor: theme.palette.background.paper,
            borderBottom: '1px solid #ddd',
          }}
        >
          <Filters onFilterChange={handleFilterChange} availableTags={availableTags} initialFilters={initialFilters} />
        </div>
      ) : (
        <div
          className="hamburger-button-container"
          style={{ padding: '16px', textAlign: 'center' }}
        >
          <Button
            variant="outlined"
            startIcon={<FilterListIcon />}
            onClick={toggleDrawer(true)}
            sx={{
              borderRadius: '20px',
              px: 2,
              py: 1,
              textTransform: 'none',
              fontSize: theme.typography.subtitle1.fontSize,
              borderColor: theme.palette.darkGreen.main,
              color: theme.palette.darkGreen.main,
              '&:hover': {
                borderColor: theme.palette.darkGreen.dark || '#163922',
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            Filters
          </Button>
        </div>
      )}

      {/* Drawer for Filters on Small Screens */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div
          className="drawer-content"
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          style={{ width: '250px' }}
        >
          <Filters onFilterChange={handleFilterChange} availableTags={availableTags} initialFilters={initialFilters} />
        </div>
      </Drawer>

      {/* Main Content: Business List and Map */}
      <Box
        className="main-content"
        display="flex"
        flexDirection={isSmallScreen ? 'column' : 'row'}
        sx={{ gap: 2, p: 2 }}
      >
        <Box
          className="business-list"
          sx={{
            flex: isSmallScreen
              ? 'none'
              : isLargeScreen
              ? '0 0 800px'
              : '0 0 350px',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            maxHeight: 'calc(100vh - 350px)',
            padding: '8px', // Add padding around the container
            '& > *': { // Ensure child elements don't inherit the padding
              width: '100%',
            }
          }}
        >
          {/* Business Cards Container */}
          <Box
            sx={{
              flex: 1,
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: isLargeScreen ? 'repeat(2, 1fr)' : '1fr',
                  gap: 2,
                  padding: '20px', // Add padding to the grid container
                  margin: '-8px', // Compensate for the parent padding
                  width: 'calc(100% + 16px)', // Compensate for the negative margin
                  '& > *': {
                    maxWidth: '100%', // Ensure cards don't overflow their grid cells
                  }
                }}
              >
                {filteredBusinesses.length > 0 ? (
                  filteredBusinesses.map((business) => (
                    <VerticalBusinessCard
                      key={business.id}
                      id={`business-${business.id}`} // Add this ID
                      business={{
                        ...business,
                        // Ensure tags are properly formatted for the component
                        tags: business.Tags || business.tags || [], // Handle both capitalizations
                      }}
                      showDescription={true}
                      isSelected={selectedBusinessId === business.id}
                      sx={{
                        transition: 'all 0.3s ease',
                        transform: selectedBusinessId === business.id ? 'scale(1.02)' : 'none',
                        boxShadow: selectedBusinessId === business.id ? 3 : 1,
                      }}
                    />
                  ))
                ) : showLoading ? (
                  <Fade in={showLoading} timeout={300}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        padding: '40px',
                        gridColumn: '1 / -1',
                        width: '100%',
                        margin: '0 auto',
                      }}
                    >
                      <CircularProgress
                        size={40}
                        thickness={4}
                        sx={{ color: theme.palette.darkGreen.main }}
                      />
                      <Typography
                        variant="h6"
                        sx={{
                          mt: 2,
                          color: theme.palette.text.secondary,
                          fontWeight: 400,
                        }}
                      >
                        Finding businesses...
                      </Typography>
                    </Box>
                  </Fade>
                ) : (
                  // Enhanced "No Businesses Found" Message
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      padding: '40px',
                      textAlign: 'center',
                      color: theme.palette.text.secondary,
                      gridColumn: '1 / -1',
                      width: '100%',
                      margin: '0 auto',
                    }}
                  >
                    <SentimentDissatisfiedOutlinedIcon
                      sx={{
                        fontSize: 80,
                        color: theme.palette.primary.main,
                        mb: 2,
                      }}
                    />
                    <Typography variant="h5" gutterBottom>
                      Oops! No Businesses Found
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 3 }}>
                      We couldn't find any businesses matching your criteria. Try
                      adjusting your filters or keywords.
                    </Typography>
                  </Box>
                )}
              </Box>

              {/* Pagination Controls */}
              {filteredBusinesses.length > 0 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    py: 2,
                    mt: 2,
                    borderTop: '1px solid #ddd',
                    backgroundColor: theme.palette.background.paper,
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (currentPage > 1) {
                        setCurrentPage(currentPage - 1);
                      }
                    }}
                    disabled={currentPage <= 1 || isLoading}
                    sx={{
                      mr: 2,
                      borderRadius: '20px',
                      textTransform: 'capitalize',
                      fontSize: theme.typography.subtitle1.fontSize,
                      backgroundColor: theme.palette.darkGreen.main,
                      '&:hover': {
                        backgroundColor:
                          theme.palette.darkGreen.dark || '#163922',
                      },
                    }}
                  >
                    Previous
                  </Button>
                  <Typography
                    variant="body1"
                    sx={{
                      mx: 2,
                      fontSize: theme.typography.subtitle1.fontSize,
                    }}
                  >
                    Page {currentPage} of {Math.ceil(totalBusinesses / perPage)}
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => {
                      const totalPages = Math.ceil(totalBusinesses / perPage);
                      if (currentPage < totalPages) {
                        setCurrentPage(currentPage + 1);
                      }
                    }}
                    disabled={
                      currentPage >= Math.ceil(totalBusinesses / perPage) ||
                      isLoading
                    }
                    sx={{
                      ml: 2,
                      borderRadius: '20px',
                      textTransform: 'capitalize',
                      fontSize: theme.typography.subtitle1.fontSize,
                      backgroundColor: theme.palette.darkGreen.main,
                      '&:hover': {
                        backgroundColor:
                          theme.palette.darkGreen.dark || '#163922',
                      },
                    }}
                  >
                    Next
                  </Button>
                </Box>
              )}

              {/* Loading Indicator */}
              {showLoading && (
                <Fade in={showLoading} timeout={300}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ mt: 2 }}
                  >
                    <CircularProgress
                      size={30}
                      thickness={4}
                      sx={{ color: theme.palette.darkGreen.main }}
                    />
                  </Box>
                </Fade>
              )}
            </Box>
          </Box>
        </Box>

        {/* Map Component */}
        <Box
          className="map"
          sx={{
            flex: 1,
            height: isSmallScreen ? '300px' : 'auto',
            minHeight: '300px',
          }}
        >
          {!locationLoading ? (
            <MapComponent
              key={`${effectiveCity}-${effectiveRegion}`} // Add this key prop
              businesses={filteredBusinesses}
              {...calculateMapView(filteredBusinesses)}
              onMarkerClick={handleMarkerClick}
            />
          ) : (
            <Typography variant="h6">Loading map...</Typography>
          )}
        </Box>
      </Box>

      <Footer />
    </div>
  );
};

export default Explore;
