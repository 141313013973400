import React, { useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { 
  Box, 
  Card, 
  CardContent, 
  Typography, 
  Avatar, 
  Stack,
  Divider,
  Button
} from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import LanguageIcon from '@mui/icons-material/Language';
import { useTheme } from '@mui/material/styles';

const AboutUs = () => {
  const theme = useTheme();
  const [hoveredCard, setHoveredCard] = useState(null);

  const confettiProps = {
    force: 0.6,
    duration: 2500,
    particleCount: 30,
    width: 400,
    colors: ['#4CAF50', '#2196F3', '#FF9800', '#E91E63', '#9C27B0']
  };

  const teamMembers = [
    {
      name: 'John Watson',
      role: 'Full Stack Developer',
      linkedin: 'https://www.linkedin.com/in/watson-john/',
      github: 'https://github.com/Watson-John',
      avatarUrl: null,
    },
    {
      name: 'Nabin Paudyal',
      role: 'Full Stack Developer',
      linkedin: null,
      github: 'https://github.com/naween321',
      website: 'https://navinpoudel.com.np/',
      avatarUrl: null,
    },
    {
      name: 'Sneha Khatiwada',
      role: 'UI/UX Designer',
      linkedin: 'https://www.linkedin.com/in/snehaha-k/',
      github: null,
      avatarUrl: null,
    },
    {
      name: 'Satya Nagunoori',
      role: 'Developer',
      linkedin: 'https://www.linkedin.com/in/satya-nagunoori-pr/',
      github: 'https://github.com/SatyaNagu',
      avatarUrl: null,
    },
    {
      name: 'Zorif Tahasildar',
      role: 'Developer',
      linkedin: 'https://www.linkedin.com/in/zorif/',
      github: 'https://github.com/awquack',
      avatarUrl: null,
    }
  ];

  return (
    <>
      <Header />
      <Box
        sx={{
          minHeight: 'calc(100vh - 64px - 100px)',
          py: 6, // Reduced from 10
          pt: 4, // Added specific top padding
          px: 2,
          backgroundColor: theme.palette.background.paper, // Updated to match header
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <Box sx={{ maxWidth: 1200, margin: '0 auto', px: { xs: 2, md: 3 }, position: 'relative' }}>
          <Typography variant="h3" component="h1" align="center" gutterBottom sx={{ mb: 4, mt: 0 }}>
            Meet Our Team
          </Typography>

          <Box sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: '1fr',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)'
            },
            gap: 4
          }}>
            {teamMembers.map((member, index) => (
              <Card
                key={index}
                onMouseEnter={() => setHoveredCard(index)}
                onMouseLeave={() => setHoveredCard(null)}
                sx={{
                  position: 'relative',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  backgroundColor: '#ffffff',
                  borderRadius: 4,
                  border: `1px solid ${theme.palette.grey[400]}`,
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: 6
                  }
                }}
                elevation={3}
              >
                {hoveredCard === index && (
                  <Box sx={{ position: 'absolute', top: -20, left: '50%', transform: 'translateX(-50%)', zIndex: 2 }}>
                    <ConfettiExplosion {...confettiProps} />
                  </Box>
                )}
                <CardContent sx={{ p: 4, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                  <Stack spacing={2} alignItems="center" flexGrow={1}>
                    <Avatar
                      src={member.avatarUrl}
                      sx={{
                        bgcolor: 'primary.main',
                        width: 100,
                        height: 100,
                        fontSize: '3rem',
                        boxShadow: 2,
                        mb: 1,
                        color: '#fff'
                      }}
                    >
                      {member.name.charAt(0).toUpperCase()}
                    </Avatar>
                    <Typography variant="h5" component="h2" fontWeight="bold">
                      {member.name}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                      {member.role}
                    </Typography>
                    <Divider flexItem sx={{ width: '100%', my: 2 }} />

                    {/* Contact Section */}
                    <Typography variant="body2" fontWeight="bold" sx={{ color: theme.palette.text.secondary, mb: 1 }}>
                      Connect with Me:
                    </Typography>

                    <Stack direction="row" spacing={1}>
                      {member.website && (
                        <Button
                          href={member.website}
                          target="_blank"
                          rel="noopener noreferrer"
                          variant="contained"
                          color="primary"
                          startIcon={<LanguageIcon />}
                          sx={{
                            textTransform: 'none',
                            backgroundColor: theme.palette.darkGreen.main,
                            '&:hover': {
                              backgroundColor: theme.palette.darkGreen.dark,
                            },
                          }}
                        >
                          Website
                        </Button>
                      )}
                      {member.linkedin && (
                        <Button
                          href={member.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                          variant="contained"
                          color="primary"
                          startIcon={<LinkedInIcon />}
                          sx={{
                            textTransform: 'none',
                            '&:hover': {
                              backgroundColor: theme.palette.primary.dark,
                            },
                          }}
                        >
                          LinkedIn
                        </Button>
                      )}
                      {member.github && (
                        <Button
                          href={member.github}
                          target="_blank"
                          rel="noopener noreferrer"
                          variant="outlined"
                          color="primary"
                          startIcon={<GitHubIcon />}
                          sx={{
                            textTransform: 'none',
                            borderColor: theme.palette.primary.main,
                            '&:hover': {
                              borderColor: theme.palette.primary.dark,
                              color: theme.palette.primary.dark
                            },
                          }}
                        >
                          GitHub
                        </Button>
                      )}
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default AboutUs;
