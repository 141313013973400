const fetchClient = async (url, options = {}) => {
    const apiURL = process.env.REACT_APP_API_URL;
    const finalURL = `${apiURL}${url}`;
    const token = localStorage.getItem('token');

    // Add Authorization if token exists
    const defaultHeaders = token ? { Authorization: `Token ${token}` } : {};

    // Dynamically set Content-Type
    const isFormData = options.body instanceof FormData;
    const requestHeaders = {
        ...defaultHeaders,
        ...options.headers,
    };

    // Only set Content-Type if it's not already set
    if (!isFormData && !requestHeaders['Content-Type']) {
        requestHeaders['Content-Type'] = 'application/json';
    }

    // Remove Content-Type header if FormData is used
    if (isFormData) {
        delete requestHeaders['Content-Type'];
    }

    const requestOptions = {
        ...options,
        headers: requestHeaders,
    };

    try {
        const response = await fetch(finalURL, requestOptions);
        console.log(response);
        if (!response.ok) {
            if (response.status === 401) {
                throw new Error('Unauthorized - Redirecting to login');
            } else {
                return response;
            }
        }
        return response;
    } catch (error) {
        console.error('Fetch error:', error.message);
        throw error;
    }
};


export default fetchClient
