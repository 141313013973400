import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import MessageCard from './MessageCard';

const Mailbox = () => {
  const theme = useTheme();
  const [messages, setMessages] = useState([
    {
      id: 1,
      senderName: 'Jessica Chen',
      senderEmail: 'jessica.chen@gmail.com',
      subject: 'Unique dining experiences in Worcester',
      date: '2024-02-15',
      time: '3:43 PM',
      isRead: false,
      replies: [
        {
          from: 'Sara Parker',
          date: '2024-02-15',
          time: '4:30 PM',
          content: 'Thank you for reaching out! I would love to discuss this further.'
        }
      ],
      body: `Hello Sara,
      
I hope this email finds you well. My name is Jessica, and I am reaching out on behalf of Yummy Dumplings.`,
    },
    {
      id: 2,
      senderName: 'Michael Smith',
      senderEmail: 'michael.smith@restaurant.com',
      subject: 'Restaurant Week Collaboration',
      date: '2024-02-14',
      time: '2:15 PM',
      isRead: false,
      replies: [],
      body: `Hi Sara,

Would you be interested in participating in Worcester Restaurant Week?`,
    },
    {
      id: 3,
      senderName: 'Emma Wilson',
      senderEmail: 'emma.wilson@foodblog.com',
      subject: 'Food Blog Feature Request',
      date: '2024-02-13',
      time: '11:20 AM',
      isRead: true,
      replies: [
        {
          from: 'Sara Parker',
          date: '2024-02-13',
          time: '1:45 PM',
          content: 'We would be honored to be featured in your blog.'
        },
        {
          from: 'Emma Wilson',
          date: '2024-02-13',
          time: '2:30 PM',
          content: 'Great! Let\'s schedule a time for the photoshoot.'
        }
      ],
      body: `Dear Sara,

I'm a local food blogger and would love to feature Yummy Dumplings.`,
    }
  ]);

  const [selectedMessage, setSelectedMessage] = useState(null);

  const handleMessageClick = (messageId) => {
    const selected = messages.find((message) => message.id === messageId);
    setSelectedMessage(selected);
  };

  const handleMarkAsRead = () => {
    setMessages(messages.map(message => ({
      ...message,
      isRead: true,
    })));
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{
        width: '380px', // Increased from 300px
        backgroundColor: '#fff',
        borderRight: '1px solid #ddd',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        height: 'calc(100vh - 120px)',
        overflowY: 'auto',
      }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <h2 style={{ margin: 0 }}>Inquiries</h2>
          <button
            onClick={handleMarkAsRead}
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'pointer',
              padding: 0,
            }}
          >
            <DoneAllIcon sx={{ width: '20px', height: '20px', color: theme.palette.primary.main }} />
          </button>
        </div>
        {messages.map((message) => (
          <MessageCard
            key={message.id}
            message={message}
            isSelected={selectedMessage?.id === message.id}
            onClick={() => handleMessageClick(message.id)}
          />
        ))}
      </div>

      <div style={{
        flex: 1,
        backgroundColor: '#fff',
        marginLeft: '20px',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        height: 'calc(100vh - 120px)',
        overflowY: 'auto',
      }}>
        {selectedMessage ? (
          <div>
            <h2 style={{ fontSize: '24px', marginBottom: '20px' }}>{selectedMessage.subject}</h2>
            
            <div style={{ 
              maxWidth: '800px',  // Add max width constraint
              margin: '0 auto'    // Center the thread container
            }}>
              {/* Original message */}
              <div style={{ 
                padding: '20px',
                backgroundColor: '#f9f9f9',
                borderRadius: '8px',
                marginBottom: '20px',
                width: '100%',    // Ensure full width within container
              }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                  <p style={{ margin: 0 }}>
                    <strong>From:</strong> {selectedMessage.senderName} ({selectedMessage.senderEmail})
                  </p>
                  <p style={{ margin: 0, color: '#666' }}>
                    {selectedMessage.date} {selectedMessage.time}
                  </p>
                </div>
                <div style={{ marginTop: '20px', whiteSpace: 'pre-line' }}>
                  {selectedMessage.body}
                </div>
              </div>

              {/* Reply thread */}
              {selectedMessage.replies.map((reply, index) => (
                <div
                  key={index}
                  style={{
                    padding: '20px',
                    backgroundColor: reply.from === 'Sara Parker' ? '#f0f7f4' : '#f9f9f9',
                    borderRadius: '8px',
                    marginBottom: '10px',
                    width: reply.from === 'Sara Parker' ? 'calc(100% - 30px)' : '100%', // Adjust width to account for indent
                    marginLeft: reply.from === 'Sara Parker' ? '30px' : '0',
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <p style={{ margin: 0 }}><strong>From:</strong> {reply.from}</p>
                    <p style={{ margin: 0, color: '#666' }}>{reply.date} {reply.time}</p>
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    {reply.content}
                  </div>
                </div>
              ))}

              {/* Reply box */}
              <div style={{ 
                marginTop: '20px',
                width: '100%'     // Ensure full width within container
              }}>
                <textarea
                  placeholder="Type your reply..."
                  style={{
                    width: '100%',
                    minHeight: '100px',
                    padding: '10px',
                    borderRadius: '8px',
                    border: '1px solid #ddd',
                    resize: 'vertical',
                    boxSizing: 'border-box'  // Include padding in width calculation
                  }}
                />
                <button
                  style={{
                    marginTop: '10px',
                    padding: '8px 20px',
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                    border: 'none',
                    borderRadius: '20px',
                    cursor: 'pointer',
                  }}
                >
                  Send Reply
                </button>
              </div>
            </div>
          </div>
        ) : (
          <p style={{ color: '#888', textAlign: 'center', marginTop: '40px' }}>
            Select a message to view the conversation
          </p>
        )}
      </div>
    </div>
  );
};

export default Mailbox;
