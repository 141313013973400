// src/components/Header.js

import React, { useEffect, useState, useContext } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  Menu,
  MenuItem,
  Link as MuiLink,
  Avatar,
  IconButton,
  Tooltip,
  Badge,
  ClickAwayListener // Add this import
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { logoWithSlogan } from '../assets'; // Adjust the path as necessary
import SwalFire from './SwalFire';
import SearchBarComponent from './SearchBar'; // Import the SearchBar component
import userServices from "../services/user";
import { AuthContext } from './AuthContext'; // Import AuthContext
import NotificationPanel from "../pages/NotificaionPanel";
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';

const { userDetail } = userServices;

function Header({ showSearchBar }) { // Removed isAuthenticated and userRole from props
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  
  // Add notifications state
  const [notifications, setNotifications] = useState([
    {
      user: "Jessica",
      avatar: "", // Add appropriate avatar image
      time: "May 05, 2025 at 3:43PM",
      isRead: false,
    },
    // ... add more notifications as needed
  ]);

  // Consume AuthContext
  const { isAuthenticated, userRole, logout } = useContext(AuthContext);

  // Update user details when isAuthenticated or userRole changes
  useEffect(() => {
    const fetchUserDetails = async () => {
      setIsLoading(true);
      const userUuid = localStorage.getItem("uuid");
      if (userUuid && isAuthenticated) {
        try {
          const detail = await userDetail(userUuid);
          setUser(detail);
        } catch (error) {
          console.error("Error fetching user detail:", error);
        }
      } else {
        setUser({});
      }
      setIsLoading(false);
    };

    fetchUserDetails();
  }, [isAuthenticated, userRole]); // Add dependencies to trigger re-fetch

  // Handle menu opening
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle menu closing
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Update the handleLogout function
  const handleLogout = () => {
    logout(); // Call the logout function from context
    setAnchorEl(null);
    SwalFire({
      title: "Logged Out!",
      text: "",
      icon: "success",
      background: "green",
      color: "white",
      timer: 5000
    });
    navigate('/'); // Navigate to home page directly
  };

  const handleDashboardClick = () => {
    navigate('/dashboard');
    handleClose();
  };

  const handleIconClick = () => {
    setIsPanelVisible((prev) => !prev);
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: theme.palette.background.paper,
          boxShadow: 'none',
          height: showSearchBar ? '100px' : '76px',
          transition: 'height 0.3s ease',
        }}
      >
        <Toolbar
          sx={{
            position: 'relative',
            height: showSearchBar ? '100px' : '64px',
            minHeight: '64px !important',
            alignItems: showSearchBar ? 'flex-start' : 'center',
            padding: showSearchBar ? '10px 24px' : '0 24px',
            transition: 'all 0.3s ease',
          }}
        >
          {/* Logo Section */}
          <Box sx={{ display: 'flex', alignItems: 'flex-start', paddingTop: '8px' }}>
            <MuiLink component={Link} to="/" sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                component="img"
                src={logoWithSlogan}
                alt="Logo with Slogan"
                sx={{ height: '50px', width: 'auto' }}
              />
            </MuiLink>
          </Box>

          {/* Centered Search Bar */}
          {showSearchBar && (
            <Box
              sx={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                width: '60%',
                minWidth: '400px',
                maxWidth: '900px',
                '@media (max-width: 900px)': {
                  width: '70%',
                },
                '@media (max-width: 600px)': {
                  width: '90%',
                  minWidth: '280px',
                },
              }}
            >
              <SearchBarComponent />
            </Box>
          )}

          {/* Auth Section */}
          <Box sx={{ marginLeft: 'auto', paddingTop: '8px', display: 'flex', alignItems: 'center' }}>
            {isAuthenticated && (
              <ClickAwayListener onClickAway={() => setIsPanelVisible(false)}>
                <div style={{ 
                  marginRight: '20px',
                  position: 'relative'
                }}>
                  <IconButton
                    color="primary"
                    onClick={handleIconClick}
                    size="medium"
                  >
                    <Badge badgeContent={notifications.filter(n => !n.isRead).length} color="error">
                      <NotificationsOutlinedIcon style={{ color: '#1F4A31' }} />
                    </Badge>
                  </IconButton>
                  {isPanelVisible && (
                    <div className="notification-panel-container">
                      <NotificationPanel
                        notifications={notifications}
                        setNotifications={setNotifications}
                      />
                    </div>
                  )}
                </div>
              </ClickAwayListener>
            )}
            {isAuthenticated && userRole === "user" ? (
              <div>
                <Tooltip title="User Profile">
                  <IconButton
                    onClick={handleMenu}
                    size="small"
                    aria-controls={open ? 'profile-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    sx={{
                      borderRadius: '30px',
                      padding: '8px 16px',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      },
                      transition: 'background-color 0.3s'
                    }}
                  >
                    <p style={{ marginRight: "10px" }}>{user?.fullName}</p>
                    {!isLoading && user?.fullName && (
                      <Avatar
                        sx={{
                          bgcolor: 'primary.main',
                          width: 45,
                          height: 45,
                          fontSize: '1.5rem',
                        }}
                      >
                        {user.fullName.charAt(0).toUpperCase()}
                      </Avatar>
                    )}
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  id="profile-menu"
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    elevation: 3,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 4px 8px rgba(0,0,0,0.2))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem onClick={handleDashboardClick}>Dashboard</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            ) : isAuthenticated && userRole === "business" ? (
              <div>
                <Tooltip title="Business User Profile">
                  <IconButton
                    onClick={handleMenu}
                    size="small"
                    aria-controls={open ? 'profile-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    sx={{
                      borderRadius: '30px',
                      padding: '8px 16px',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      },
                      transition: 'background-color 0.3s'
                    }}
                  >
                    <p style={{ marginRight: "10px" }}>{user?.fullName}</p>
                    {!isLoading && user?.fullName && (
                      <Avatar
                        sx={{
                          bgcolor: 'primary.main',
                          width: 45,
                          height: 45,
                          fontSize: '1.5rem',
                        }}
                      >
                        {user.fullName.charAt(0).toUpperCase()}
                      </Avatar>
                    )}
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  id="profile-menu"
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    elevation: 3,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 4px 8px rgba(0,0,0,0.2))',

                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <h3 style={{ padding: "0 15px" }}>Business User</h3>
                  <MenuItem onClick={handleDashboardClick}>Dashboard</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            ) : (
              <>
                <MuiLink
                  component={Link}
                  to="/login"
                  sx={{
                    marginRight: theme.spacing(2),
                    fontFamily: theme.typography.fontFamily,
                    color: theme.palette.darkGreen.main,
                    textDecoration: 'none',
                    fontSize: theme.typography.subtitle1.fontSize,
                    fontWeight: 600,
                    '&:hover': { textDecoration: 'underline' },
                  }}
                >
                  Login
                </MuiLink>
                <Button
                  variant="contained"
                  color="darkGreen"
                  onClick={handleMenu}
                  sx={{
                    borderRadius: '20px',
                    px: 3,
                    py: 1,
                    textTransform: 'none',
                    fontSize: theme.typography.subtitle1.fontSize,
                    backgroundColor: theme.palette.darkGreen.main,
                    '&:hover': { backgroundColor: theme.palette.darkGreen.dark || '#163922' },
                  }}
                >
                  Sign Up
                </Button>
              </>
            )}

            {!isAuthenticated && (
              <Menu
                anchorEl={anchorEl}
                id="profile-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 3,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 4px 8px rgba(0,0,0,0.2))',

                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem component={Link} to="/signup-user" onClick={handleClose}>
                  Sign Up as User
                </MenuItem>
                <MenuItem component={Link} to="/signup-business" onClick={handleClose}>
                  Sign Up as Business
                </MenuItem>
              </Menu>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar /> {/* Spacer to account for fixed AppBar */}
    </>
  );
}

export default Header;
