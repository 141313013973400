import React from 'react';

const MessageCard = ({ message, isSelected, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        marginBottom: '15px',
        padding: '15px',
        cursor: 'pointer',
        backgroundColor: isSelected ? '#f0f0f0' : '#fff',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        borderLeft: isSelected ? '4px solid #1F4A31' : 'none',
        transition: 'all 0.2s ease',
        minHeight: '80px', // Add minimum height
      }}
      onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.02)'}
      onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <div style={{ display: 'flex', alignItems: 'flex-start', flex: 1 }}>
          <div
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              backgroundColor: '#1F4A31',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '12px',
              fontSize: '18px',
              flexShrink: 0, // Prevent avatar from shrinking
            }}
          >
            {message.senderName[0].toUpperCase()}
          </div>
          <div style={{ flex: 1, minWidth: 0 }}> {/* Add minWidth: 0 to allow text truncation */}
            <strong style={{ 
              display: 'block',
              marginBottom: '4px',
              fontSize: '14px'
            }}>{message.senderName}</strong>
            <span style={{ 
              color: '#666',
              fontSize: '13px',
              display: 'block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '160px' // Adjust based on your needs
            }}>{message.subject}</span>
          </div>
        </div>
        <div style={{ 
          textAlign: 'right',
          marginLeft: '8px',
          flexShrink: 0 // Prevent date/time from shrinking
        }}>
          <div style={{ fontSize: '12px', color: '#888' }}>{message.date}</div>
          <div style={{ fontSize: '12px', color: '#888' }}>{message.time}</div>
          {message.replies && message.replies.length > 0 && (
            <div style={{ 
              fontSize: '12px', 
              color: '#1F4A31',
              marginTop: '4px',
              fontWeight: '500'
            }}>
              {message.replies.length} {message.replies.length === 1 ? 'reply' : 'replies'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageCard;
