import fetchClient from "../fetchClient";

const businessSignUp = async (payload) =>{
    const url = `/businesses/sign-up/`;
    try {
        const requestOptions = {method: "POST", body: payload}
        const response = await fetchClient(url, requestOptions)
        if (!response.status === 201) {
          throw new Error('Business sign Up failed');
        }
        const data = await response.json();
        console.log('Business SignUp', data);
        return data;
      } catch (error) {
        console.log(error);
      }
}

const newBusinessesService = async () =>{
    const url = `/businesses/new/`;
    try {
        const requestOptions = {method: "GET"}
        const response = await fetchClient(url, requestOptions)
        if (!response.status === 200) {
          throw new Error('Error');
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.log(error);
      }
}

export default {businessSignUp, newBusinessesService}
