// src/pages/Dashboard/DashboardTab.js
import React, { useState } from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import yummyDumplings from "../../assets/yummyDumplings.png";

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend);

const DashboardTab = ({ theme }) => {
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [notifications, setNotifications] = useState([
    // ... your notifications data
  ]);

  // Pie Chart Data
  const pieChartData = {
    labels: ['=5 Stars', '>5 Stars', '>2 Stars'],
    datasets: [
      {
        data: [70, 25, 5],
        backgroundColor: [theme.palette.primary.main, '#CACACA', '#DDD7C8'],
        hoverBackgroundColor: [
          theme.palette.primary.light,
          '#A9A9A9',
          '#C9C0AF'
        ],
      },
    ],
  };

  const pieChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        display: false
      },
    },
  };

  // Bar Chart Data
  const visitorData = {
    labels: ['January', 'February', 'March'],
    datasets: [
      {
        label: 'Visitors',
        data: [300, 400, 350],
        backgroundColor: [theme.palette.primary.main, '#CACACA', '#DDD7C8'],
      },
    ],
  };

  const visitorOptions = {
    plugins: {
      legend: { display: false },
      title: { display: true, text: 'Visitor Data' },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  // Reach Chart Data
  const reachData = {
    labels: ['January', 'February', 'March'],
    datasets: [
      {
        label: 'Reach',
        data: [500, 600, 550],
        borderColor: '#36A2EB',
        backgroundColor: [theme.palette.primary.main, '#DDD7C8', '#CACACA'],
      },
    ],
  };

  const reachOptions = {
    plugins: {
      legend: { display: false, position: 'top' },
      title: { display: true, text: 'Reach Data' },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div>
      {/* Header Section */}
      <div style={{ display: 'flex', marginBottom: '30px' }}>
        {/* Left Side */}
        <div style={{ flex: 1, paddingRight: '20px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={yummyDumplings}
              alt="Yummy Dumplings"
              style={{ width: '150px', height: '150px', borderRadius: '50%', marginRight: '20px' }}
            />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '20px' }}>
              <h3 style={{ marginBottom: '10px' }}>Yummy Dumplings</h3>
              <p style={{ margin: '5px 0' }}><strong>Admin:</strong> Sara Parker</p>
              <p style={{ margin: '5px 0' }}><strong>Position:</strong> Sr. HR Manager</p>
              <p style={{ margin: '5px 0' }}><strong>Email:</strong> abc@gmail.com</p>
              <p style={{ margin: '5px 0' }}><strong>Contact number:</strong> 888 000 000</p>
            </div>
          </div>
        </div>

        {/* Divider */}
        <div style={{ width: '2px', backgroundColor: '#ddd', margin: '0 20px' }}></div>

        {/* Right Side */}
        <div style={{ flex: 1, paddingLeft: '20px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '150px', height: '150px', marginRight: '20px' }}>
              <Pie data={pieChartData} options={pieChartOptions} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginBottom: '20px',
                paddingLeft: '50px',
              }}
            >
              <h3 style={{ marginBottom: '10px' }}>Rating</h3>
              <ul style={{ padding: 0, listStyle: 'none', margin: 0 }}>
                <li>
                  <span
                    style={{
                      display: 'inline-block',
                      width: '10px',
                      height: '10px',
                      backgroundColor: theme.palette.primary.main,
                      marginRight: '5px',
                    }}
                  ></span>
                  =5 Stars
                </li>
                <li>
                  <span
                    style={{
                      display: 'inline-block',
                      width: '10px',
                      height: '10px',
                      backgroundColor: '#DDD7C8',
                      marginRight: '5px',
                    }}
                  ></span>
                  &gt;5 Stars
                </li>
                <li>
                  <span
                    style={{
                      display: 'inline-block',
                      width: '10px',
                      height: '10px',
                      backgroundColor: '#CACACA',
                      marginRight: '5px',
                    }}
                  ></span>
                  &gt;2 Stars
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Charts Section */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        {/* Visitor Bar Chart */}
        <div style={{
          width: '45%',
          backgroundColor: '#F2F2F2',
          padding: '0px',
          marginRight: '20px',
          borderRadius: '5px',
        }}>
          <div style={{ width: '100%', height: '300px' }}>
            <Bar data={visitorData} options={visitorOptions} />
          </div>
        </div>

        {/* Reach Bar Chart */}
        <div style={{
          width: '45%',
          backgroundColor: '#F2F2F2',
          padding: '0px',
          marginLeft: '20px',
          borderRadius: '5px',
        }}>
          <div style={{ width: '100%', height: '300px' }}>
            <Bar data={reachData} options={reachOptions} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardTab;
