import React from 'react';
import { Box, Typography } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { styled } from '@mui/system';

const DropZone = styled(Box)(({ theme, isDragOver }) => ({
  border: '2px dashed',
  borderColor: isDragOver ? theme.palette.primary.main : theme.palette.grey[400],
  borderRadius: '8px',
  padding: theme.spacing(2),
  textAlign: 'center',
  cursor: 'pointer',
  backgroundColor: isDragOver ? theme.palette.action.hover : 'transparent',
}));

const DropBox = ({ isDragOver, onDragOver, onDragLeave, onDrop, onClick }) => {
  return (
    <DropZone
      isDragOver={isDragOver}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onClick={onClick}
    >
      <PhotoCameraIcon fontSize="large" color="action" />
      <Typography variant="body2" color="text.secondary">
        Drag and drop file here, or click to select files
      </Typography>
      <Typography variant="caption" color="text.secondary">
        (Max file size 10MB)
      </Typography>
    </DropZone>
  );
};

export default DropBox;