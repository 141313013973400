// src/components/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';

// Create the AuthContext
export const AuthContext = createContext();

// AuthProvider component to wrap around your app
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [userUUID, setUserUUID] = useState(null);
  const [token, setToken] = useState(null); // Added token state

  // Check auth status more frequently
  useEffect(() => {
    const checkAuthStatus = () => {
      const storedToken = localStorage.getItem('token');
      const role = localStorage.getItem('role');
      const uuid = localStorage.getItem('uuid');

      if (storedToken && role && uuid) {
        setIsAuthenticated(true);
        setUserRole(role);
        setUserUUID(uuid);
        setToken(storedToken);
      } else {
        // Clear states if no valid auth data found
        setIsAuthenticated(false);
        setUserRole(null);
        setUserUUID(null);
        setToken(null);
      }
    };

    // Check immediately
    checkAuthStatus();

    // Set up an interval to check auth status
    const interval = setInterval(checkAuthStatus, 1000);

    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures this runs once on mount

  // Function to handle login (you should implement this based on your auth flow)
  const login = (newToken, role, uuid) => {
    localStorage.setItem('token', newToken);
    localStorage.setItem('role', role);
    localStorage.setItem('uuid', uuid);
    setIsAuthenticated(true);
    setUserRole(role);
    setUserUUID(uuid);
    setToken(newToken); // Update the token state
  };

  // Logout function to clear authentication data
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('uuid');
    setIsAuthenticated(false);
    setUserRole(null);
    setUserUUID(null);
    setToken(null); // Clear the token state
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userRole, userUUID, token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
