import React, {useContext} from "react";
import Header from "../components/Header";
import {Link} from "react-router-dom"
import {AuthContext} from "../components/AuthContext";


const NotFound = () => {
    const {isAuthenticated, userRole, userUUID} = useContext(AuthContext);
    return (
        <>
            <Header
                isAuthenticated={isAuthenticated}
                userRole={userRole}
                userUUID={userUUID}
                showSearchBar={true}
            />
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh", // Full viewport height
                textAlign: "center" // Center-align text
            }}>
                <h1 style={{color: "#1F4A31", fontSize: "3rem", fontWeight: "700", margin: 0}}>SORRY!</h1>
                <p style={{fontWeight: "700", marginTop: "5px", fontSize: "1.8rem"}}>The page you’re looking for doesn’t
                    exist.</p>
                <p style={{fontSize: "2rem"}}>Go back to the
                    <Link to="/" style={{color: "#1F4A31"}}> homepage </Link>
                    or check the URL for errors.</p>
            </div>
        </>
    );
}

export default NotFound;