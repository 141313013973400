// src/services/LocationContext.js
import React, { createContext, useState, useEffect } from 'react';

export const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState({
    city: null,
    region: null,
    latitude: null,
    longitude: null,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        // Primary API: ipapi.co
        const response = await fetch('https://ipapi.co/json/', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Primary API HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (!data || !data.city || !data.region_code || !data.latitude || !data.longitude) {
          throw new Error('Invalid data received from primary API');
        }

        setLocation({
          city: data.city,
          region: data.region_code,
          latitude: data.latitude,
          longitude: data.longitude,
        });

      } catch (primaryError) {
        console.error("Primary location fetch failed:", primaryError.message);

        try {
          // Backup API: ipapi.com
          const backupResponse = await fetch('https://api.ipapi.com/api/check?access_key=YOUR_API_KEY');
          const backupData = await backupResponse.json();

          if (
            backupData &&
            backupData.city &&
            backupData.region_code &&
            backupData.latitude &&
            backupData.longitude
          ) {
            setLocation({
              city: backupData.city,
              region: backupData.region_code,
              latitude: backupData.latitude,
              longitude: backupData.longitude,
            });
          } else {
            throw new Error('Invalid backup API data');
          }
        } catch (backupError) {
          console.error("Backup location fetch failed:", backupError.message);
          // Final fallback to San Francisco
          setLocation({ 
            city: "San Francisco", 
            region: "CA",
            latitude: 37.7749, // Latitude for San Francisco
            longitude: -122.4194, // Longitude for San Francisco
          });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchLocation();
  }, []);

  return (
    <LocationContext.Provider value={{ ...location, setLocation, loading }}>
      {children}
    </LocationContext.Provider>
  );
};
