import { useState, useEffect } from "react";
import {Link, useLocation, useNavigate} from "react-router-dom"
import {
    Card, Container, CardContent, Box, TextField, Button,
    InputAdornment, IconButton
} from '@mui/material';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import Nav from "../Nav"
import GoogleIcon from "../assets/googleicon.png"
import "../styles/Login.css"
import userLogin from "../services/userLogin"
import Footer from "../components/Footer"
import SwalFire from "../components/SwalFire"
import googleLogin from "../services/googleLogin"


const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleGoogleLogin = async () =>{
        const apiURL = process.env.REACT_APP_API_URL
        window.location.href = `${apiURL}/auth/google/login/`
    }

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            email: email,
            password: password,
        };
        console.log(payload)
        const result = await userLogin(payload)
        if (result) {
            SwalFire({title:"Login Success!", text:"", 
                icon:"success", background: "green", color:"white", timer: 5000 })
            navigate("/")
        }else{
            SwalFire({title:"", text:"Invalid email or password", 
                icon:"error", background: "red", color:"white", timer: 5000 })
        }
    };

    useEffect(()=>{
        const queryParams = new URLSearchParams(location.search);
        const status = queryParams.get('status');
        if (status === "400"){
            SwalFire({title:"Couldn't login",
                text:"A user with that email already exists",
                icon:"error", background: "red", color:"white", timer: 5000 })
        }
    })

    return (
        <div>
            <Nav />
            <div style={{ height: "6rem" }}></div>
            <Container maxWidth="sm">
                <Box component="form" onSubmit={handleSubmit}>

                    <Card sx={{ width: '100%', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: "10px" }}>
                        <CardContent sx={{ textAlign: "left" }}>
                            <h1>Welcome to<br></br><span style={{ color: "#1F4A31" }}>LIAN</span> </h1>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    type='email'
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <TextField
                                    label="Password"
                                    type={showPassword ? "text" : "password"}
                                    variant="outlined"
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    inputProps={{
                                        'aria-label': 'password',
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleTogglePassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityRoundedIcon /> : <VisibilityOffRoundedIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <br></br>
                                <Link to="/" style={{ textDecoration: "none", color: "#1D407B" }}><span style={{ fontWeight: "500" }}>Forgot Password?</span></Link>
                            </div>
                        </CardContent>
                        <div style={{ textAlign: "center", marginBottom: "20px" }}>
                            <Button type="submit" style={{
                                width:
                                    "60%", backgroundColor: "#1F4A31",
                                color: "white", borderRadius: "15px",
                                padding: "10px", fontWeight: "500", fontSize: "1rem"
                            }}>Sign in</Button>
                            <p style={{ fontWeight: 500, fontSize: "0.9rem", marginBottom: 0 }}>No account? <Link to="/signup-user" style={{ textDecoration: "none" }}>Sign Up</Link></p>
                            <div style={{ color: "grey", marginTop: "0px" }} className="line-container">
                                <span>or</span>
                            </div>
                            <Button onClick={handleGoogleLogin} style={{
                                padding: "10px", width: "50%",
                                color: "#1D407B", borderRadius: "14px",
                                boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)", fontWeight: 500
            
                            }}>
                                <img alt="google-icon" src={GoogleIcon} style={{ marginRight: "5px" }}></img>
                                Continue With Google
                            </Button>
                            <br></br>
                            <br></br>
                            <p style={{ fontSize: "0.8rem", fontWeight: 500 }}>By clicking Continue, you agree to LIAN's
                                <Link> User Agreement </Link> <br></br>
                                <Link>Privacy Policy, </Link>
                                <Link>Cookie Policy, </Link>
                            </p>
                        </div>

                    </Card>
                </Box>

            </Container>
            <br></br><br></br>
            <Footer/>
        </div >
    )
}

export default Login