import fetchClient from "../fetchClient";


const categoryGet = async () =>{
    const url = `/categories/`;
    try {
        const requestOptions = {method: "GET"}
        const response = await fetchClient(url, requestOptions)
        if (!response.status === 200) {
          throw new Error('Category get failed');
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.log(error);
      }
}

export default categoryGet
