// src/pages/Dashboard/AccountTab.js
import React, { useState, useRef, useEffect } from 'react';
import { Avatar } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import profAcc from "../../assets/profAcc.png";

const AccountTab = ({ theme, user }) => {
  const [isFirstDropdownVisible, setIsFirstDropdownVisible] = useState(false);
  const [isSecondDropdownVisible, setIsSecondDropdownVisible] = useState(false);
  const [isThirdDropdownVisible, setIsThirdDropdownVisible] = useState(false);
  const panelRef = useRef(null);

  const toggleDropdown = (dropdown) => {
    if (dropdown === 'first') {
      setIsFirstDropdownVisible(!isFirstDropdownVisible);
      setIsSecondDropdownVisible(false);
      setIsThirdDropdownVisible(false);
    } else if (dropdown === 'second') {
      setIsSecondDropdownVisible(!isSecondDropdownVisible);
      setIsFirstDropdownVisible(false);
      setIsThirdDropdownVisible(false);
    } else if (dropdown === 'third') {
      setIsThirdDropdownVisible(!isThirdDropdownVisible);
      setIsFirstDropdownVisible(false);
      setIsSecondDropdownVisible(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (panelRef.current && !panelRef.current.contains(event.target)) {
        setIsFirstDropdownVisible(false);
        setIsSecondDropdownVisible(false);
        setIsThirdDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: '10px'
      }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingTop: '30px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              sx={{
                width: 200,
                height: 200,
                fontSize: '80px',
                bgcolor: 'primary.main',
                marginRight: '20px'
              }}
            >
              {user?.fullName?.charAt(0).toUpperCase()}
            </Avatar>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '100px' }}>
              <h1 style={{ marginBottom: '10px' }}>{user?.fullName}</h1>
            </div>
          </div>

          {/* Set Password */}
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginLeft: '10px',
            marginTop: '20px'
          }}>
            <button style={{
              marginBottom: '10px',
              background: 'none',
              border: 'none',
              color: theme.palette.primary.main,
              fontSize: '25px',
              cursor: 'pointer',
              fontWeight: 'bold',
              padding: 5
            }} onClick={() => toggleDropdown('first')}>
              Set your Password
            </button>

            <div
              style={{
                maxHeight: isFirstDropdownVisible ? '400px' : '0',
                overflow: 'hidden',
                opacity: isFirstDropdownVisible ? '1' : '0',
                transition: 'max-height 0.6s ease, opacity 0.4s ease-in',
                marginBottom: isFirstDropdownVisible ? '10px' : '0',
                marginTop: isFirstDropdownVisible ? '-15px' : '0',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              {isFirstDropdownVisible && (
                <div style={{
                  padding: '2px',
                  background: 'none',
                  borderRadius: '5px',
                  marginLeft: '5px',
                  width: '100%'
                }}>
                  <p style={{
                    margin: '5px 0',
                    fontSize: '16px',
                    color: '#333',
                    textAlign: 'left',
                    marginBottom: '10px'
                  }}>
                    Set your password not to login easily using just your Email
                  </p>

                  <p style={{
                    margin: '5px 0',
                    fontSize: '14px',
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                    textAlign: 'left',
                  }}>
                    Current Password
                  </p>

                  <input
                    type="password"
                    placeholder="Current Password"
                    style={{
                      width: '300px',
                      height: '40px',
                      padding: '8px',
                      marginBottom: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '5px',
                      backgroundColor: '#F2F2F2'
                    }}
                  />

                  <p style={{
                    margin: '5px 0',
                    fontSize: '14px',
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                    textAlign: 'left',
                  }}>
                    New Password
                  </p>

                  <input
                    type="password"
                    placeholder="New Password"
                    style={{
                      width: '300px',
                      height: '40px',
                      padding: '8px',
                      marginBottom: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '5px',
                      backgroundColor: '#F2F2F2'
                    }}
                  />

                  <p style={{
                    margin: '5px 0',
                    fontSize: '14px',
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                    textAlign: 'left',
                  }}>
                    Verify New Password
                  </p>

                  <input
                    type="password"
                    placeholder="Verify New Password"
                    style={{
                      width: '300px',
                      height: '40px',
                      padding: '8px',
                      marginBottom: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '5px',
                      backgroundColor: '#F2F2F2'
                    }}
                  />
                  <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
                    <button
                      style={{
                        width: '120px',
                        height: '35px',
                        padding: '4px',
                        borderRadius: '50px',
                        border: 'none',
                        backgroundColor: theme.palette.primary.main,
                        color: '#ffffff',
                        fontSize: '14px',
                      }}>
                      Save Changes
                    </button>

                    <button
                      style={{
                        padding: '4px',
                        borderRadius: '8px',
                        border: '1px solid #ccc',
                        backgroundColor: '#F2F2F2',
                      }}>
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>

            {/* Deactivate Account */}
            <div
              style={{
                maxHeight: isSecondDropdownVisible ? '200px' : '0',
                overflow: 'hidden',
                opacity: isSecondDropdownVisible ? '1' : '0',
                transition: 'max-height 1.0s ease, opacity 0.7s ease-in',
                marginBottom: isSecondDropdownVisible ? '10px' : '0',
                marginTop: isSecondDropdownVisible ? '-15px' : '0',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              {isSecondDropdownVisible && (
                <div style={{
                  padding: '2px',
                  background: 'none',
                  borderRadius: '5px',
                  marginLeft: '5px',
                  width: '100%'
                }}>
                  <p style={{
                    margin: '5px 0',
                    fontSize: '16px',
                    color: '#333',
                    textAlign: 'left',
                    marginBottom: '20px',
                    fontWeight: 'bold',
                    marginTop: '20px'
                  }}>
                    Are you sure about Deactivating your account?
                  </p>
                  <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
                    <button
                      style={{
                        width: '100px',
                        height: '35px',
                        padding: '4px',
                        borderRadius: '50px',
                        border: 'none',
                        backgroundColor: theme.palette.primary.main,
                        color: '#ffffff',
                        fontSize: '14px',
                      }}>
                      Deactivate
                    </button>
                  </div>
                </div>
              )}
            </div>

            {/* Delete Account */}
            <button style={{
              marginBottom: '10px',
              background: 'none',
              border: 'none',
              color: theme.palette.primary.main,
              fontSize: '25px',
              cursor: 'pointer',
              fontWeight: 'bold',
              padding: 5
            }} onClick={() => toggleDropdown('third')}>
              Delete the Account
            </button>

            <div
              style={{
                maxHeight: isThirdDropdownVisible ? '200px' : '0',
                overflow: 'hidden',
                opacity: isThirdDropdownVisible ? '1' : '0',
                transition: 'max-height 0.8s ease, opacity 0.5s ease-in',
                marginBottom: isThirdDropdownVisible ? '10px' : '0',
                marginTop: isThirdDropdownVisible ? '-15px' : '0',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              {isThirdDropdownVisible && (
                <div style={{
                  padding: '2px',
                  background: 'none',
                  borderRadius: '5px',
                  marginLeft: '5px',
                  width: '100%'
                }}>
                  <p style={{
                    margin: '5px 0',
                    fontSize: '16px',
                    color: '#333',
                    textAlign: 'left',
                    marginBottom: '20px',
                    fontWeight: 'bold',
                    marginTop: '20px'
                  }}>
                    Are you sure about Deleting your account?
                  </p>
                  <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
                    <button
                      style={{
                        width: '85px',
                        height: '35px',
                        padding: '4px',
                        borderRadius: '50px',
                        border: 'none',
                        backgroundColor: theme.palette.primary.main,
                        color: '#ffffff',
                        fontSize: '14px',
                      }}>
                      Delete
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountTab;
