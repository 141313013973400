// src/components/WriteReviewModal.js

import React, { useState, useRef, useEffect, useContext } from 'react';
import { formatDistanceToNow } from 'date-fns';
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  Rating,
  IconButton,
  Alert,
  Snackbar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { styled } from '@mui/system';
import leoProfanity from 'leo-profanity';
import { AuthContext } from './AuthContext';
import DropBox from './DropBox';

// Initialize leo-profanity with default dictionary
leoProfanity.loadDictionary();

// Styles for the modal's content box
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '90%', sm: 500 },
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

// Styled component for photo thumbnails
const Thumbnail = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 100,
  height: 100,
  borderRadius: '8px',
  overflow: 'hidden',
  border: `1px solid ${theme.palette.grey[300]}`,
}));

// Helper function to calculate relative dates
const calculateRelativeDate = (dateString) => {
  if (!dateString) return 'Just now';
  const date = new Date(dateString);
  return isNaN(date.getTime()) ? 'Just now' : formatDistanceToNow(date, { addSuffix: true });
};

const WriteReviewModal = ({ onAddReview, businessId }) => {
  const { isAuthenticated, token } = useContext(AuthContext);

  // State to control modal visibility
  const [open, setOpen] = useState(false);

  // State to handle form inputs
  const [formData, setFormData] = useState({
    rating: 0,
    comment: '',
  });

  // State to handle form validation errors
  const [errors, setErrors] = useState({
    rating: false,
    profanity: false,
    photos: false,
  });

  // State for character count
  const [charCount, setCharCount] = useState(0);

  // State for uploaded photos
  const [photos, setPhotos] = useState([]);

  // State for drag-and-drop
  const [isDragOver, setIsDragOver] = useState(false);

  // Ref for file input
  const fileInputRef = useRef(null);

  // State for profanity errors
  const [profanityError, setProfanityError] = useState('');

  // State for Snackbar error
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);

  // Handler to open the modal
  const handleOpen = () => {
    if (isAuthenticated) {
      setOpen(true);
    } else {
      setShowErrorSnackbar(true);
    }
  };

  // Handler to close the modal and reset form
  const handleClose = () => {
    setOpen(false);
    setFormData({ rating: 0, comment: '' });
    setErrors({
      rating: false,
      profanity: false,
      photos: false,
    });
    setCharCount(0);
    setPhotos([]);
    setProfanityError('');
    setIsDragOver(false);
  };

  // Handler for rating change
  const handleRatingChange = (event, newValue) => {
    setFormData((prev) => ({
      ...prev,
      rating: newValue,
    }));
    // Reset rating error if a rating is selected
    if (newValue > 0) {
      setErrors((prev) => ({
        ...prev,
        rating: false,
      }));
    }
  };

  // Handler for comment change
  const handleCommentChange = (e) => {
    const { value } = e.target;
    if (value.length <= 1000) {
      setFormData((prev) => ({
        ...prev,
        comment: value,
      }));
      setCharCount(value.length);
      // Since comment is optional, only set profanity error if there's text
      if (value.trim() !== '') {
        const hasProfanity = leoProfanity.check(value);
        setErrors((prev) => ({
          ...prev,
          profanity: hasProfanity,
        }));
        setProfanityError(
          hasProfanity ? 'Please remove profanity from your review.' : ''
        );
      } else {
        setErrors((prev) => ({
          ...prev,
          profanity: false,
        }));
        setProfanityError('');
      }
    }
  };

  // Handler for file selection
  const handleFiles = (selectedFiles) => {
    const newPhotos = [...photos];
    const errorMessages = [];

    Array.from(selectedFiles).forEach((file) => {
      if (newPhotos.length >= 3) {
        errorMessages.push('Maximum of 3 photos allowed.');
        return;
      }

      if (file.size > 10 * 1024 * 1024) {
        errorMessages.push(`${file.name} exceeds the 10MB size limit.`);
        return;
      }

      // Validate file type (images only)
      if (!file.type.startsWith('image/')) {
        errorMessages.push(`${file.name} is not an image file.`);
        return;
      }

      newPhotos.push(file);
    });

    if (errorMessages.length > 0) {
      setErrors((prev) => ({
        ...prev,
        photos: errorMessages.join(' '),
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        photos: false,
      }));
    }

    setPhotos(newPhotos);
  };

  // Handler for file input change
  const handleFileChange = (e) => {
    handleFiles(e.target.files);
  };

  // Handler for drag events
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    handleFiles(e.dataTransfer.files);
  };

  // Handler to trigger file input click
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  // Handler to remove a photo
  const handleRemovePhoto = (index) => {
    const updatedPhotos = [...photos];
    updatedPhotos.splice(index, 1);
    setPhotos(updatedPhotos);
    setErrors((prev) => ({ ...prev, photos: false }));
  };

  // Handler for form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    let validationErrors = {
      rating: formData.rating === 0,
      profanity: false,
      photos: false,
    };

    // Validation checks
    if (formData.comment.trim() !== '' && leoProfanity.check(formData.comment)) {
      validationErrors.profanity = true;
      setProfanityError('Please remove profanity from your review.');
    } else {
      setProfanityError('');
    }

    setErrors(validationErrors);

    const hasErrors = Object.values(validationErrors).some((error) => error);

    if (!hasErrors) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/reviews/add`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
          body: JSON.stringify({
            businessId: businessId,
            rating: formData.rating,
            comment: formData.comment.trim(),
            images: [], // Will be handled later
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to submit review');
        }

        const newReview = await response.json();

        // Create a properly formatted review object
        const formattedReview = {
          id: newReview.id,
          reviewer: newReview.reviewer?.fullName || 'Anonymous',
          date: calculateRelativeDate(newReview.createdAt),
          rating: newReview.rating,
          comment: newReview.comment,
          photos: newReview.images || [],
          isReported: false,
        };

        onAddReview(formattedReview);
        handleClose();
      } catch (error) {
        console.error('Error submitting review:', error);
        setShowErrorSnackbar(true);
      }
    }
  };

  // Cleanup object URLs to prevent memory leaks
  useEffect(() => {
    return () => {
      photos.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [photos]);

  return (
    <>
      {/* Write a Review Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        startIcon={<RateReviewIcon />}
        sx={{
          fontSize: '1.15rem',
          borderRadius: '20px',
          textTransform: 'none',
          width: '200px',
          height: '50px',
        }}
        aria-label="Write a Review"
      >
        Write a Review
      </Button>

      {/* Modal Component */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="write-review-modal-title"
        aria-describedby="write-review-modal-description"
      >
        <Box sx={modalStyle}>
          {/* Modal Header */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography id="write-review-modal-title" variant="h2" component="h2">
              Write a Review
            </Typography>
            <IconButton onClick={handleClose} aria-label="Close Modal">
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Review Form */}
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
            sx={{ mt: 2 }}
          >
            {/* Rating Field */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography component="legend" variant='h6' sx={{ mr: 1 }}>
                Rating:
              </Typography>
              <Rating
                name="rating"
                value={formData.rating}
                onChange={handleRatingChange}
                precision={0.5}
                size="large"
              />
            </Box>
            {/* Required Rating Message */}
            {errors.rating && (
              <Typography variant="body2" color="error" sx={{ mb: 2 }}>
                Rating is required to submit.
              </Typography>
            )}

            {/* Comment Field with Character Counter */}
            <Box sx={{ position: 'relative', mb: 2 }}>
              <TextField
                fullWidth
                label="Your Review"
                name="comment"
                multiline
                rows={4}
                value={formData.comment}
                onChange={handleCommentChange}
                error={errors.profanity}
                helperText={errors.profanity ? profanityError : ''}
                InputProps={{
                  sx: {
                    resize: 'both',
                  },
                }}
              />
              {/* Character Counter */}
              <Typography
                variant="caption"
                color={charCount > 1000 ? 'error' : 'text.secondary'}
                sx={{ position: 'absolute', bottom: 8, right: 12 }}
              >
                {charCount}/1000
              </Typography>
            </Box>

            {/* Photo Upload Section */}
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                Upload Photos (Optional)
              </Typography>
              <DropBox
                isDragOver={isDragOver}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={handleUploadClick}
              />
              {/* Hidden File Input */}
              <input
                type="file"
                accept="image/*"
                multiple
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />
              {/* Display Uploaded Photos */}
              {photos.length > 0 && (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2, gap: 2 }}>
                  {photos.map((file, index) => (
                    <Thumbnail key={index}>
                      <Box
                        component="img"
                        src={URL.createObjectURL(file)}
                        alt={`Upload Preview ${index + 1}`}
                        sx={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                      <IconButton
                        size="small"
                        sx={{
                          position: 'absolute',
                          top: -10,
                          right: -10,
                          backgroundColor: 'rgba(255,255,255,0.7)',
                        }}
                        onClick={() => handleRemovePhoto(index)}
                        aria-label={`Remove photo ${index + 1}`}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Thumbnail>
                  ))}
                </Box>
              )}
              {/* Photo Upload Errors */}
              {errors.photos && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {errors.photos}
                </Alert>
              )}
            </Box>

            {/* Submit Button */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                borderRadius: '20px',
                textTransform: 'none',
                fontSize: '1.1rem',
              }}
              aria-label="Submit Review"
            >
              Submit Review
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Snackbar for Error Message */}
      <Snackbar
        open={showErrorSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowErrorSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setShowErrorSnackbar(false)}
          severity="error"
          sx={{ width: '100%' }}
        >
          Please log in to write a review.
        </Alert>
      </Snackbar>
    </>
  );
};

export default WriteReviewModal;
