// src/components/SearchBar.js
import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Box,
  TextField,
  Button,
  Divider,
  CircularProgress,
  Popper, // Import Popper from MUI
} from '@mui/material';
import { Autocomplete } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { useMediaQuery } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { LocationContext } from '../services/LocationContext';
import queryString from 'query-string';

// Styled Components
const SearchBarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  justifyContent: 'center',
  backgroundColor: 'white',
  borderRadius: '30px',
  padding: theme.spacing(0.7),
  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  width: '100%',
  maxWidth: '900px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(1),
    width: '100%',
  },
}));

const SearchInput = styled(TextField)(({ theme }) => ({
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '0',
  maxWidth: '500px',
  backgroundColor: 'transparent',
  borderRadius: '30px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '30px',
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '& .MuiInputBase-input': {
      fontSize: '1.2rem',
      padding: theme.spacing(1, 3),
      paddingLeft: theme.spacing(2.5), // Adjust left padding to align text
    },
  },
}));

const LocationInput = styled(Autocomplete)(({ theme }) => ({
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '0',
  maxWidth: '300px',
  '& .MuiAutocomplete-inputRoot': {
    paddingLeft: theme.spacing(1.5), // Match padding with SearchInput
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));


const SearchButton = styled(Button)(({ theme }) => ({
  flexShrink: 0,
  padding: theme.spacing(1, 3),
  marginRight: theme.spacing(1),
  backgroundColor: theme.palette.darkGreen.main,
  color: theme.palette.darkGreen.contrastText,
  fontSize: theme.typography.subtitle1.fontSize,
  borderRadius: '30px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.darkGreen.dark || '#163922',
  },
}));

const ResponsiveDivider = styled(Divider)(({ theme, orientation }) => ({
  backgroundColor: theme.palette.grey[300],
  ...(orientation === 'vertical'
    ? {
        width: '1px',
        height: 'auto',
        margin: theme.spacing(0, 2),
      }
    : {
        width: '100%',
        height: '1px',
        margin: theme.spacing(2, 0),
      }),
}));

// Custom Popper with 7px offset using Popper.js modifiers
const StyledPopper = (props) => {
  return (
    <Popper
      {...props}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 5], // [skidding, distance]
          },
        },
      ]}
      placement="bottom-start"
      
      // remove the top left and right boarder radius
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '0',
        },
      }}



    />
  );
};

function SearchBarComponent() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { city, region, setLocation } = useContext(LocationContext);
  const userLocation = city && region ? `${city}, ${region}` : '';

  const [searchKeywords, setSearchKeywords] = useState(''); // For search keywords input
  const [locationValue, setLocationValue] = useState('');
  const [locations, setLocations] = useState([]);
  const [isValidLocation, setIsValidLocation] = useState(true);
  const [locationsLoading, setLocationsLoading] = useState(true); // State to track loading
  const validationTimeoutRef = useRef(null); // Ref for validation timeout

  const navigate = useNavigate(); // Initialize useNavigate
  const currentLocation = useLocation(); // Get current location

  // Ref to track if locationValue has been set by user interaction
  const isUserInteracting = useRef(false);

  // Fetch locations on mount
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/locations`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error fetching locations: ${response.status} ${response.statusText}`);
        }
        return response.json();
      })
      .then((data) => {
        const locationOptions = data.map((loc) => `${loc.city}, ${loc.state}`);
        setLocations(locationOptions);
      })
      .catch((error) => console.error('Error fetching locations:', error))
      .finally(() => setLocationsLoading(false));
  }, []);

  // Set initial state from query parameters on initial render
  useEffect(() => {
    const { keywords, city: queryCity, state: queryState } = queryString.parse(currentLocation.search);
    let timeoutId; // Declare timeoutId to track the timeout

    if (currentLocation.pathname.startsWith('/explore')) {
      setSearchKeywords(keywords || '');
      if (queryCity && queryState) {
        const newLocation = `${queryCity}, ${queryState}`;
        setLocationValue(newLocation);
        // Delay validation by 300 milliseconds
        timeoutId = setTimeout(() => {
          const isValid = locations.includes(newLocation);
          setIsValidLocation(isValid);
        }, 300);
      } else {
        setLocationValue(userLocation);
        // Delay validation by 300 milliseconds
        timeoutId = setTimeout(() => {
          setIsValidLocation(true);
        }, 300);
      }
    } else {
      // On Home page
      setSearchKeywords('');
      setLocationValue(userLocation);
      // Delay validation by 300 milliseconds
      timeoutId = setTimeout(() => {
        setIsValidLocation(true);
      }, 300);
    }

    return () => {
      // Clear the timeout when dependencies change or component unmounts
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation.search, currentLocation.pathname, locationsLoading, locations]);

  // Handle location input change
  const handleLocationChange = (event, newValue) => {
    isUserInteracting.current = true; // User is interacting
    setLocationValue(newValue || '');
    // Clear any existing timeout
    if (validationTimeoutRef.current) {
      clearTimeout(validationTimeoutRef.current);
    }
    // Set a new timeout to validate after a delay
    validationTimeoutRef.current = setTimeout(() => {
      const isValid = locations.includes(newValue);
      setIsValidLocation(isValid);
      if (isValid && newValue) {
        const [newCity, newState] = newValue.split(', ').map((s) => s.trim());
        setLocation({ city: newCity, region: newState });
      }
    }, 300); // Adjust the delay as needed (300ms)
  };

  // Clear timeout on unmount
  useEffect(() => {
    return () => {
      if (validationTimeoutRef.current) {
        clearTimeout(validationTimeoutRef.current);
      }
    };
  }, []);

  // Handle search submission
  const handleSearch = (event) => {
    event.preventDefault();
    if (!isValidLocation) {
      console.log('Invalid location. Please select a valid location.');
      return;
    }
    const [searchCity, searchState] = locationValue.split(', ').map((s) => s.trim());
    const searchUrl = `/explore?keywords=${encodeURIComponent(
      searchKeywords
    )}&city=${encodeURIComponent(searchCity)}&state=${encodeURIComponent(searchState)}`;

    // If we're already on the explore page with the same path, force a reload
    if (currentLocation.pathname === '/explore') {
      window.location.href = searchUrl;
    } else {
      navigate(searchUrl);
    }
  };

  // Determine divider orientation based on screen size
  const dividerOrientation = isSmallScreen ? 'horizontal' : 'vertical';

  return (
    <Box
      className="search-bar"
      sx={{
        backgroundColor: 'transparent',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {/* Wrap in form to handle Enter key */}
      <form onSubmit={handleSearch} style={{ width: '100%' }}>
        <SearchBarContainer>
          <SearchInput
            id="keywords-input"
            placeholder="What are you looking for?"
            variant="outlined"
            value={searchKeywords} // Use state value
            onChange={(e) => setSearchKeywords(e.target.value)} // Update state on change
          />
          <ResponsiveDivider orientation={dividerOrientation} flexItem />
          <LocationInput
            options={locations}
            value={locationValue}
            onChange={handleLocationChange}
            // Remove freeSolo to restrict to predefined options
            autoHighlight
            autoSelect
            disableClearable
            PopperComponent={StyledPopper} // Use custom Popper to adjust dropdown position
            ListboxProps={{
              style: {
                maxHeight: '200px',
                fontSize: '1.3rem',
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Where?"
                variant="outlined"
                error={!isValidLocation && locationValue !== '' && !locationsLoading} // Prevent error during loading
                helperText={
                  !isValidLocation && locationValue !== '' && !locationsLoading
                    ? 'Please select a location from the list'
                    : ''
                }
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',
                    '& fieldset': { border: 'none' },
                    '&:hover fieldset': { borderColor: theme.palette.primary.main },
                    '&.Mui-focused fieldset': { borderColor: theme.palette.primary.main },
                    '& .MuiInputBase-input': {
                      fontSize: '1.2rem',
                      padding: theme.spacing(1, 3),
                    },
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {locationsLoading ? <CircularProgress size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          <SearchButton type="submit" variant="contained">
            Search
          </SearchButton>
        </SearchBarContainer>
      </form>
    </Box>
  );
}

export default SearchBarComponent;
