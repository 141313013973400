// src/components/Filters.js

import React, { useState, useEffect, useMemo, useContext } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Autocomplete,
  TextField,
  Chip,
  Rating,
  Typography,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/system';
import debounce from 'lodash.debounce';
import { Autocomplete as GoogleAutocomplete } from '@react-google-maps/api';
import { LocationContext } from '../services/LocationContext';
import { useLoadScript } from '@react-google-maps/api';

// src/constants/categories.js

 const categories = [
  { id: 1, name: 'Health & Wellness' },
  { id: 2, name: 'Retail' },
  { id: 3, name: 'Entertainment & Recreation' },
  { id: 4, name: 'Services' },
  { id: 5, name: 'Food & Drink' },
  { id: 6, name: 'Other' },
  { id: 7, name: 'Community & Government' },
];

const StyledResetButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1, 3),
  marginRight: theme.spacing(1),
  backgroundColor: theme.palette.darkGreen.main,
  color: theme.palette.darkGreen.contrastText,
  fontSize: theme.typography.subtitle1.fontSize,
  borderRadius: '30px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.darkGreen.dark || '#163922',
  },
}));

const commonBorderStyles = {
  borderWidth: '2px',
  borderColor: '#2E7D32', // darkGreen.main color
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 150,  // Increased minimum width for better display
  '& .MuiInputBase-root': {
    borderRadius: '30px',
    padding: theme.spacing(1, 3),
    fontSize: theme.typography.subtitle1.fontSize,
    height: '45px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      ...commonBorderStyles,
    },
    '&:hover fieldset': {
      ...commonBorderStyles,
    },
    '&.Mui-focused fieldset': {
      ...commonBorderStyles,
    },
  },
  '& .MuiInputLabel-root': {
    top: '-4px',
    color: theme.palette.darkGreen.main,
    '&.Mui-focused': {
      color: theme.palette.darkGreen.main,
    },
  },
}));

const CategoryFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 250, // Adjusted to be wide enough for the longest category name
  '& .MuiInputBase-root': {
    borderRadius: '30px',
    padding: theme.spacing(1, 3),
    fontSize: theme.typography.subtitle1.fontSize,
    height: '45px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      ...commonBorderStyles,
    },
    '&:hover fieldset': {
      ...commonBorderStyles,
    },
    '&.Mui-focused fieldset': {
      ...commonBorderStyles,
    },
  },
  '& .MuiInputLabel-root': {
    top: '-4px',
    color: theme.palette.darkGreen.main,
    '&.Mui-focused': {
      color: theme.palette.darkGreen.main,
    },
  },
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  minWidth: 200,
  '& .MuiOutlinedInput-root': {
    borderRadius: '30px',
    padding: theme.spacing(1, 3),  // Match padding with other inputs
    fontSize: theme.typography.subtitle1.fontSize,
    height: '45px',
    '& fieldset': {
      ...commonBorderStyles,
    },
    '&:hover fieldset': {
      ...commonBorderStyles,
    },
    '&.Mui-focused fieldset': {
      ...commonBorderStyles,
    },
  },
  '& .MuiInputLabel-root': {
    top: '-4px',  // Match label position with other inputs
    color: theme.palette.darkGreen.main,
    '&.Mui-focused': {
      color: theme.palette.darkGreen.main,
    },
  },
  '& .MuiAutocomplete-input': {  // Add this to align the input text
    padding: '0 !important',
  },
  // Add styles for the popup and options
  '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
    backgroundColor: theme.palette.darkGreen.light || '#e8f5e9',
  },
  '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]': {
    backgroundColor: theme.palette.darkGreen.main,
    color: theme.palette.darkGreen.contrastText,
  }
}));

const StyledDistanceFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 150,  // Match other fields
  '& .MuiInputBase-root': {
    borderRadius: '30px',
    padding: theme.spacing(1, 3),
    fontSize: theme.typography.subtitle1.fontSize,
    height: '45px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      ...commonBorderStyles,
    },
    '&:hover fieldset': {
      ...commonBorderStyles,
    },
    '&.Mui-focused fieldset': {
      ...commonBorderStyles,
    },
  },
  '& .MuiInputLabel-root': {
    top: '-4px',
    color: theme.palette.darkGreen.main,
    '&.Mui-focused': {
      color: theme.palette.darkGreen.main,
    },
  },
}));

const StyledAddressInput = styled(TextField)(({ theme }) => ({
  minWidth: 250,
  '& .MuiOutlinedInput-root': {
    borderRadius: '30px',
    padding: theme.spacing(1, 3),
    fontSize: theme.typography.subtitle1.fontSize,
    height: '100%',  // Changed to 100%
    display: 'flex',
    alignItems: 'center',  // Added
    '& fieldset': {
      ...commonBorderStyles,
      border: 'none',
    },
    '&:hover fieldset': {
      ...commonBorderStyles,
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      ...commonBorderStyles,
      border: 'none',
    },
  },
  '& .MuiInputBase-input': {
    padding: '0 !important',
    height: 'auto !important',  // Added
    lineHeight: 'normal',  // Added
  },
}));

const LocationGroupContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '45px',  // Match other components
  borderRadius: '30px',
  border: `2px solid #2E7D32`,  // darkGreen.main color
  '& .MuiOutlinedInput-root': {
    border: 'none',
    height: '100%',  // Fill container height
    display: 'flex',
    alignItems: 'center',
    '& fieldset': {
      border: 'none',
    },
  },
  '& .MuiFormControl-root': {
    height: '100%',  // Fill container height
    display: 'flex',
    alignItems: 'center',
    '& .MuiOutlinedInput-root': {
      border: 'none',
      height: '100%',  // Fill container height
      display: 'flex',
      alignItems: 'center',
      '& fieldset': {
        border: 'none',
      },
    },
    '& .MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 5),  // Adjusted padding
    },
  },
}));

const Divider = styled('div')(({ theme }) => ({
  width: '1px',
  alignSelf: 'stretch',
  margin: '6px 0',  // Reduced from 8px
  backgroundColor: theme.palette.darkGreen.main,
}));

const StyledRatingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 3),
  borderRadius: '30px',
  border: `2px solid #2E7D32`,  // Updated to specified color
  height: '45px',
  '& .MuiTypography-root': {
    marginRight: theme.spacing(1),
  },
  '& .MuiRating-root': {
    display: 'flex',
    alignItems: 'center',
  },
}));

function Filters({ onFilterChange, availableTags, initialFilters }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
    libraries: ['places'],
  });

  const [price, setPrice] = useState('');
  const [category, setCategory] = useState('');
  const [tags, setTags] = useState([]);
  const [rating, setRating] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [radius, setRadius] = useState('');
  const [autocomplete, setAutocomplete] = useState(null);
  const { latitude, longitude } = useContext(LocationContext);

  // Add currentFilters state
  const [currentFilters, setCurrentFilters] = useState({
    price: '',
    category: '',
    tags: [],
    rating: 0,
    distance: null
  });

  // Add updateFilters function
  const updateFilters = (newFilters) => {
    setCurrentFilters(newFilters);
    debouncedFilterChange(newFilters);
  };

  // Debounced version of onFilterChange to prevent rapid consecutive calls
  const debouncedFilterChange = useMemo(
    () => debounce(onFilterChange, 300),
    [onFilterChange]
  );

  // Effect to notify parent component whenever filters change
  useEffect(() => {
    // Construct the filters object
    const currentFilters = {
      price,
      category,
      tags,
      rating,
      distance: selectedAddress && radius ? {
        location: autocomplete?.getPlace()?.geometry?.location 
          ? {
              lat: autocomplete.getPlace().geometry.location.lat(),
              lng: autocomplete.getPlace().geometry.location.lng(),
            }
          : { lat: latitude, lng: longitude },
        radius,
      } : null,
    };

    // Call the debounced onFilterChange prop with the current filters
    debouncedFilterChange(currentFilters);

    // Cleanup function to cancel debounce on unmount or before next call
    return () => {
      debouncedFilterChange.cancel();
    };
  }, [price, category, tags, rating, selectedAddress, radius, debouncedFilterChange]);

  // Add effect to handle initial filters
  useEffect(() => {
    if (initialFilters?.category && !category) {
      const newCategory = initialFilters.category;
      setCategory(newCategory);
      updateFilters({
        ...currentFilters,
        category: newCategory
      });
    }
  }, [initialFilters]);

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleTagsChange = (event, newTags) => {
    // Filter out any tags that aren't in availableTags
    const validTags = newTags.filter(tag => availableTags.includes(tag));
    setTags(validTags);
  };

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
  };

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const handlePlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.geometry && place.geometry.location) {
        const location = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        setSelectedAddress(place.formatted_address);
        updateFilters({
          ...currentFilters,
          distance: {
            location,
            radius: radius || null,
          },
        });
      }
    }
  };

  const handleRadiusChange = (event) => {
    const newRadius = event.target.value;
    setRadius(newRadius);
    if (selectedAddress && autocomplete?.getPlace()?.geometry?.location) {
      const location = {
        lat: autocomplete.getPlace().geometry.location.lat(),
        lng: autocomplete.getPlace().geometry.location.lng(),
      };
      updateFilters({
        ...currentFilters,
        distance: {
          location,
          radius: newRadius,
        },
      });
    }
  };

  const handleReset = () => {
    setPrice('');
    setCategory('');
    setTags([]);
    setRating(0);
    setSelectedAddress('');
    setRadius('');
  };

  const handleDeleteChip = (chipType, chipValue) => {
    if (chipType === 'price') setPrice('');
    else if (chipType === 'category') setCategory('');
    else if (chipType === 'tag') setTags(tags.filter((tag) => tag !== chipValue));
    else if (chipType === 'rating') setRating(0);
    else if (chipType === 'distance') {
      setSelectedAddress('');
      setRadius('');
    }
  };

  if (!isLoaded) {
    return <div>Loading places...</div>;
  }

  return (
    <Box sx={{ mb: 2 }}>
      <Box
        display="flex"
        alignItems="center"
        gap={2}
        sx={{ mb: 2, flexWrap: 'wrap' }}
      >
        <StyledFormControl variant="outlined">
          <InputLabel>Price</InputLabel>
          <Select
            value={price}
            onChange={handlePriceChange}
            label="Price"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                  borderRadius: '10px',
                },
              },
            }}
          >
            <MenuItem value="">
              <em>Any</em>
            </MenuItem>
            <MenuItem value="$">$ (Under $10)</MenuItem>
            <MenuItem value="$$">$$ ($11-$30)</MenuItem>
            <MenuItem value="$$$">$$$ ($31-$60)</MenuItem>
            <MenuItem value="$$$$">$$$$ (Above $60)</MenuItem>
          </Select>
        </StyledFormControl>

        <CategoryFormControl variant="outlined">
          <InputLabel>Category</InputLabel>
          <Select
            value={category}
            onChange={handleCategoryChange}
            label="Category"
            MenuProps={{
              PaperProps: {
                style: {
                  borderRadius: '10px',
                },
              },
            }}
          >
            <MenuItem value="">Any</MenuItem>
            {categories.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </CategoryFormControl>

        <StyledAutocomplete
          multiple
          options={availableTags || []}
          value={tags}
          onChange={handleTagsChange}
          disableCloseOnSelect
          renderTags={() => []}  // Changed this line to return empty array
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={`Tags`}
              placeholder={tags.length === 0 ? "Search Tags" : ""}
            />
          )}
        />

        {isLoaded && (
          <LocationGroupContainer>
            <GoogleAutocomplete
              onLoad={onLoad}
              onPlaceChanged={handlePlaceChanged}
            >
              <StyledAddressInput
                variant="outlined"
                value={selectedAddress}
                onChange={(e) => setSelectedAddress(e.target.value)}
                placeholder="Enter Location"
                InputProps={{
                  notched: false,
                }}
                InputLabelProps={{
                  shrink: false,
                }}
              />
            </GoogleAutocomplete>
            <Divider />
            <StyledDistanceFormControl 
              variant="outlined"
              sx={{ minWidth: '150px' }}
            >
              <InputLabel>Radius</InputLabel>
              <Select
                value={radius}
                onChange={handleRadiusChange}
                label="Radius"
                disabled={!selectedAddress}
              >
                <MenuItem value="">Any Distance</MenuItem>
                <MenuItem value="1">Within 1 mile</MenuItem>
                <MenuItem value="5">Within 5 miles</MenuItem>
                <MenuItem value="10">Within 10 miles</MenuItem>
              </Select>
            </StyledDistanceFormControl>
          </LocationGroupContainer>
        )}

        {/* Rating Filter - Wrapped in StyledRatingContainer */}
        <StyledRatingContainer>
          <Typography variant="body1">
            Min Rating:
          </Typography>
          <Rating
            name="min-rating"
            value={rating}
            onChange={handleRatingChange}
            precision={0.5}
          />
        </StyledRatingContainer>

        <StyledResetButton onClick={handleReset}>Reset Filters</StyledResetButton>
      </Box>

      {/* Display Applied Filters */}
      <Box display="flex" flexWrap="wrap" gap={1}>
        {price && (
          <Chip
            label={`Price: ${price}`}
            size="medium"
            onDelete={() => handleDeleteChip('price')}
            sx={{ fontSize: '0.9rem' }}
          />
        )}
        {category && (
          <Chip
            label={`Category: ${categories.find(c => c.id === parseInt(category))?.name}`}
            size="medium"
            onDelete={() => handleDeleteChip('category')}
            sx={{ fontSize: '0.9rem' }}
          />
        )}
        {/* Move tags chips here and render them first */}
        {tags.map((tag) => (
          <Chip
            key={tag}
            label={tag}
            size="medium"
            onDelete={() => handleDeleteChip('tag', tag)}
            sx={{ fontSize: '0.9rem' }}
          />
        ))}
        {rating > 0 && (
          <Chip
            label={`Min Rating: ${rating}`}
            size="medium"
            onDelete={() => handleDeleteChip('rating')}
            sx={{ fontSize: '0.9rem' }}
          />
        )}
        {selectedAddress && radius && (
          <Chip
            label={`Within ${radius} miles of ${selectedAddress}`}
            onDelete={() => handleDeleteChip('distance')}
            sx={{ fontSize: '0.9rem' }}
          />
        )}
      </Box>
    </Box>
  );
}

export default Filters;
