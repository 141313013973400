// src/pages/Business.js

import React, { useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../components/AuthContext';
import { Box, Button, Chip, Typography, Rating, Snackbar, Alert, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ShareIcon from '@mui/icons-material/Share';
import { useParams, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MapComponent from '../components/Map';
import ContactCard from '../components/ContactCard';
import ReviewCard from '../components/ReviewCard';
import WriteReviewModal from '../components/WriteReviewModal';
import GridModal from '../components/GridModal';
import { restaurantImage } from '../assets';
import { formatDistanceToNow } from 'date-fns';

// Helper function to calculate relative dates
const calculateRelativeDate = (dateString) => {
  if (!dateString) return 'Just now';
  const date = new Date(dateString);
  return isNaN(date.getTime()) ? 'Just now' : formatDistanceToNow(date, { addSuffix: true });
};

// Business Component Definition
const Business = () => {
  const { isAuthenticated, userRole, userUUID } = useContext(AuthContext);
  const [authLoaded, setAuthLoaded] = useState(false);
  const theme = useTheme();
  const { id } = useParams();
  const location = useLocation();

  const [businessData, setBusinessData] = useState(null);
  const [loadingBusiness, setLoadingBusiness] = useState(true);

  // State variables for reviews
  const [currentReviews, setCurrentReviews] = useState([]);
  const [loadingReviews, setLoadingReviews] = useState(false);
  const [reviewsError, setReviewsError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [gridModalOpen, setGridModalOpen] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [showToast, setShowToast] = useState(false);

  const isFetchingRef = useRef(false);

  // Set authLoaded based on AuthContext changes
  useEffect(() => {
    setAuthLoaded(true);
  }, [isAuthenticated, userRole, userUUID]);

  // Fetch business data when the component mounts
  useEffect(() => {
    const fetchBusinessData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/businesses/${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch business data.');
        }
        const data = await response.json();
        setBusinessData(data);
        setLoadingBusiness(false);
      } catch (error) {
        console.error('Error fetching business details:', error);
        setLoadingBusiness(false);
      }
    };
    fetchBusinessData();
  }, [id]);

  // Reset reviews when businessData changes (e.g., switching businesses)
  useEffect(() => {
    if (businessData) {
      setCurrentReviews([]);
      setCurrentPage(1);
      setTotalPages(1);
    }
  }, [businessData]);

  // Fetch reviews after business data is fetched or when page changes
  useEffect(() => {
    const fetchReviews = async () => {
      if (!businessData) return;

      // Prevent duplicate fetches
      if (isFetchingRef.current) {
        console.log('Fetch in progress. Skipping.');
        return;
      }

      setLoadingReviews(true);
      setReviewsError(null);
      isFetchingRef.current = true;

      console.log(`Fetching reviews for Business ID: ${id}, Page: ${currentPage}`);

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/reviews/business/${id}?page=${currentPage}&limit=3`);
        if (!response.ok) {
          throw new Error('Failed to fetch reviews.');
        }
        const data = await response.json();

        console.log(`Fetched Page ${currentPage}:`, data.reviews.map(r => r.id));

        const { reviews, pagination } = data;

        const mappedReviews = reviews.map((review) => ({
          id: review.id,
          reviewer: typeof review.reviewer === 'object' ? review.reviewer.fullName || 'Anonymous' : (review.reviewer || 'Anonymous'),
          date: calculateRelativeDate(review.createdAt),
          rating: review.rating,
          comment: review.comment,
          photos: review.images || [],
          isReported: review.isReported || false
        }));

        setCurrentReviews((prevReviews) => {
          const existingReviewIds = new Set(prevReviews.map(r => r.id));
          const newUniqueReviews = mappedReviews.filter(r => !existingReviewIds.has(r.id));

          console.log('Appending Reviews:', newUniqueReviews.map(r => r.id));

          return [...prevReviews, ...newUniqueReviews];
        });

        setTotalPages(pagination.totalPages);
        setLoadingReviews(false);
      } catch (error) {
        console.error('Error fetching reviews:', error);
        setReviewsError(error.message);
        setLoadingReviews(false);
      } finally {
        isFetchingRef.current = false;
      }
    };
    fetchReviews();
  }, [businessData, id, currentPage]);

  // Modify the loading check
  if (loadingBusiness || !authLoaded) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!businessData) {
    return <Typography>Business not found.</Typography>;
  }

  // Destructure main business properties
  const {
    name,
    address1,
    city,
    state,
    zip_code,
    country,
    contactNumber,
    description,
    heroImage,
    rating,
    latitude,
    longitude,
    Tags,
    email,
    menuImages,
  } = businessData;

  // Extract Tags
  const tags = Array.isArray(Tags) ? Tags.map((tag) => tag.name) : [];

  // Combine address fields and format zip code
  const formattedZipCode = zip_code ? zip_code.toString().split('.')[0] : '';
  const address = `${address1}, ${city}, ${state} ${formattedZipCode}, ${country}`;

  // Define contact object for ContactCard component
  const contact = {
    address: address,
    phone: contactNumber,
    email: email || '',
    location: {
      lat: latitude ? parseFloat(latitude) : null,
      lng: longitude ? parseFloat(longitude) : null,
    },
  };

  // Convert latitude and longitude to numbers
  const parsedLatitude = latitude ? parseFloat(latitude) : null;
  const parsedLongitude = longitude ? parseFloat(longitude) : null;

  // Define businesses array for the Map component
  const business_location =
    parsedLatitude && parsedLongitude
      ? [
          {
            id: id,
            name: name,
            location: { lat: parsedLatitude, lng: parsedLongitude },
          },
        ]
      : [];

  // Define center and zoom for the map
  const mapCenter = parsedLatitude && parsedLongitude ? { lat: parsedLatitude, lng: parsedLongitude } : { lat: 0, lng: 0 };
  const mapZoom = parsedLatitude && parsedLongitude ? 15 : 2;

  const openGridModal = () => {
    const allPhotos = currentReviews.flatMap((review) => review.photos);
    setPhotos(allPhotos);
    setGridModalOpen(true);
  };

  const closeGridModal = () => setGridModalOpen(false);

  const addReview = (newReview) => {
    // Use the reviewer name from the newReview object
    const reviewerName = newReview.reviewer || 'Anonymous';

    const mappedReview = {
      id: newReview.id,
      reviewer: reviewerName,
      date: newReview.date || calculateRelativeDate(newReview.createdAt),
      rating: newReview.rating,
      comment: newReview.comment,
      photos: newReview.photos || [],
      isReported: newReview.isReported || false,
    };
    setCurrentReviews((prevReviews) => [mappedReview, ...prevReviews]);
  };

  // Ensure menuImages is an array
  const menuImagesArray = Array.isArray(menuImages) ? menuImages : [];

  // Handle Share Button Click
  const handleShareClick = () => {
    const url = window.location.origin + location.pathname;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setShowToast(true);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  // JSX for the Reviews Section
  const ReviewsSection = (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
        Reviews
      </Typography>
      <Box
        sx={{
          width: '100px',
          height: '4px',
          borderRadius: '2px',
          bgcolor: 'primary.main',
          mt: 1,
          mb: 3,
        }}
      />
      {currentReviews.length === 0 && !loadingReviews ? (
        <Typography>No reviews yet. Be the first to review!</Typography>
      ) : (
        <>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: menuImagesArray.length > 0 ? '1fr' : 'repeat(auto-fit, minmax(400px, 1fr))',
              gap: 2,
            }}
          >
            {currentReviews.map((review) => (
              <ReviewCard
                key={review.id}
                review={review}
                fullWidth={!menuImagesArray.length}
              />
            ))}
          </Box>
          {/* Pagination Button */}
          {currentPage < totalPages && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button
                variant="contained"
                onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                disabled={loadingReviews}
              >
                {loadingReviews ? 'Loading...' : 'Load More'}
              </Button>
            </Box>
          )}
          {/* Display a message when all reviews are loaded */}
          {currentPage >= totalPages && currentReviews.length > 0 && (
            <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 2 }}>
              You have seen all reviews.
            </Typography>
          )}
        </>
      )}
    </Box>
  );

  // Render the Business Component
  return (
    <Box sx={{ bgcolor: theme.palette.background.paper, minHeight: '100vh', p: 1 }}>
      <Header
        isAuthenticated={isAuthenticated}
        userRole={userRole}
        userUUID={userUUID}
        showSearchBar={true}
      />
      <Box
        sx={{
          bgcolor: 'white',
          borderRadius: '16px',
          margin: 'auto',
          maxWidth: '1200px',
          padding: 3,
          boxShadow: 3,
          mb: 3,
          mt: 4,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            maxHeight: '400px',
            borderRadius: '20px',
            overflow: 'hidden',
          }}
        >
          <Box
            component="img"
            src={heroImage || restaurantImage}
            alt={`${name} Image`}
            sx={{
              width: '100%',
              height: '700px',
              objectFit: 'cover',
            }}
          />
          <Button
            variant="contained"
            sx={{
              textTransform: 'none',
              fontSize: '1.0rem',
              position: 'absolute',
              borderRadius: '8px',
              bottom: 20,
              right: 20,
              color: 'black',
              backgroundColor: 'white',
              transition: 'transform 0.3s ease, background-color 0.3s ease',
              '&:hover': {
                transform: 'scale(1.05)',
                backgroundColor: '#f0f0f0',
              },
            }}
            onClick={openGridModal}
          >
            All Photos
          </Button>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 4,
            mt: 3,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Box sx={{ mb: 2, textAlign: 'left' }}>
              <Typography variant="h1">{name}</Typography>
              <Rating value={rating || 0} precision={0.1} readOnly size="large" />
              <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                {tags.length > 0 ? (
                  tags.slice(0, 5).map((tag, index) => (
                    <Chip key={index} label={tag} size="small" />
                  ))
                ) : (
                  <Typography>No Tags</Typography>
                )}
              </Box>
              <Box sx={{ mb: 2, textAlign: 'left' }}>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: 'bold', display: 'inline', color: 'success.main' }}
                >
                  Open
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ display: 'inline', ml: 1, color: 'text.secondary' }}
                >
                  (10 am - 10 pm)
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    display: 'inline',
                    ml: 2,
                    color: 'primary.main',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  See opening hours
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
              <WriteReviewModal
                onAddReview={addReview}
                businessId={id}
              />
              <Button
                variant="outlined"
                color="success"
                startIcon={<ShareIcon />}
                onClick={handleShareClick}
                sx={{
                  fontSize: '1.15rem',
                  borderWidth: '3px',
                  borderRadius: '20px',
                  textTransform: 'none',
                  width: '200px',
                  height: '50px',
                }}
              >
                Share
              </Button>
            </Box>

            {/* Snackbar for Toast Message */}
            <Snackbar
              open={showToast}
              autoHideDuration={3000}
              onClose={() => setShowToast(false)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <Alert
                onClose={() => setShowToast(false)}
                severity="success"
                sx={{ width: '100%' }}
              >
                Copied to your Clipboard!
              </Alert>
            </Snackbar>

            <Box sx={{ mb: 2, textAlign: 'left' }}>
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                Description
              </Typography>
              <Box
                sx={{
                  width: '140px',
                  height: '4px',
                  borderRadius: '2px',
                  bgcolor: 'primary.main',
                  mt: 1,
                }}
              />
              <Typography variant="body1" sx={{ mt: 2 }}>
                {description}
              </Typography>
            </Box>

            {menuImagesArray.length > 0 && (
              <Box sx={{ mb: 2, textAlign: 'left' }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                  Menu
                </Typography>
                <Box
                  sx={{
                    width: '70px',
                    height: '4px',
                    borderRadius: '2px',
                    bgcolor: 'primary.main',
                    mt: 1,
                  }}
                />
                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                  {menuImagesArray.map((imgSrc, index) => (
                    <Box
                      component="img"
                      key={index}
                      src={imgSrc}
                      alt="Menu Item"
                      sx={{
                        width: '100%',
                        maxWidth: '200px',
                        borderRadius: '8px',
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}

            {/* Reviews Section */}
            <Box>{ReviewsSection}</Box>
          </Box>

          <Box sx={{ flex: 1 }}>
            <Box sx={{ mb: 2, height: '400px' }}>
              <MapComponent
                businesses={business_location}
                center={mapCenter}
                zoom={mapZoom}
              />
            </Box>
            <ContactCard contact={contact} />
          </Box>
        </Box>

        {menuImagesArray.length > 0 && ReviewsSection}
      </Box>

      <Footer />
      <GridModal open={gridModalOpen} onClose={closeGridModal} photos={photos} />
    </Box>
  );
};

export default Business;
