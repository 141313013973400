// src/components/ReviewCard.js

import React, { useState, useContext } from 'react';
import {
  Box,
  Typography,
  Rating,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Snackbar,
  Tooltip,
  Button,
} from '@mui/material';
import PhotoModal from './PhotoModal';
import ReportIcon from '@mui/icons-material/Report';
import leoProfanity from 'leo-profanity';
import { AuthContext } from '../components/AuthContext'; // Ensure correct path

// Initialize leo-profanity with the default dictionary
leoProfanity.loadDictionary();

const ReviewCard = ({ review, fullWidth }) => {
  const { isAuthenticated, token } = useContext(AuthContext); 
  const [profanityError, setProfanityError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  const [loginDialogOpen, setLoginDialogOpen] = useState(false); // New state for login dialog
  const [reportReason, setReportReason] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [reporting, setReporting] = useState(false); // To handle loading state
  const [reportError, setReportError] = useState('');

  const handleOpen = (index) => {
    setCurrentIndex(index);
    setModalOpen(true);
  };

  const handleClose = () => setModalOpen(false);

  const handleReportDialogOpen = () => {
    if (!isAuthenticated) {
      // Open the custom login dialog instead of alert
      setLoginDialogOpen(true);
      return;
    }
    setReportReason('');
    setReportError('');
    setReportDialogOpen(true);
  };

  const handleReportReasonChange = (e) => {
    const reason = e.target.value;
    setReportReason(reason);
    // Check for profanity
    const hasProfanity = leoProfanity.check(reason);
    setProfanityError(hasProfanity);
  };

  const handleReportDialogClose = () => setReportDialogOpen(false);

  const handleLoginDialogClose = () => setLoginDialogOpen(false);

  const handleConfirmReport = async () => {
    setReporting(true);
    setReportError('');

    console.log('Auth Token:', token);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/reviews/report/${review.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`, 
        },
        body: JSON.stringify({ reason: reportReason }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to report the review.');
      }

      // Optionally, update the UI to reflect that the review has been reported
      setSnackbarOpen(true);
      handleReportDialogClose();
    } catch (error) {
      console.error('Error reporting review:', error);
      setReportError(error.message);
    } finally {
      setReporting(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          mb: 2,
          p: 2,
          border: '1px solid #ddd',
          borderRadius: '8px',
          backgroundColor: '#fff',
          maxWidth: fullWidth ? '100%' : '800px',
          width: '100%',
          boxShadow: 3,
        }}
      >
        <Avatar
          sx={{
            bgcolor: 'primary.main',
            mr: 2,
            width: 56,
            height: 56,
            fontSize: '1.5rem',
          }}
        >
          {String(review.reviewer || 'Anonymous').charAt(0).toUpperCase()}
        </Avatar>
        <Box sx={{ flex: 1, textAlign: 'left' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {String(review.reviewer || 'Anonymous')}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="body2" color="textSecondary">
                {review.date}
              </Typography>
              <Tooltip title="Report this review" arrow>
                <IconButton
                  onClick={handleReportDialogOpen}
                  sx={{ color: 'grey.500', '&:hover': { color: 'grey.800' } }}
                  disabled={review.isReported} // Disable if already reported
                >
                  <ReportIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Rating value={review.rating} readOnly size="small" sx={{ mb: 1 }} />
          <Typography variant="body1" sx={{ mb: 1 }}>
            {review.comment}
          </Typography>
          {review.photos && review.photos.length > 0 && (
            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
              {review.photos.slice(0, 3).map((photo, index) => (
                <Box
                  key={index}
                  component="img"
                  src={photo}
                  alt={`Review Photo ${index + 1}`}
                  sx={{
                    width: 80,
                    height: 80,
                    borderRadius: 1,
                    objectFit: 'cover',
                    cursor: 'pointer',
                    boxShadow: 2,
                    transition: 'transform 0.3s',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                  }}
                  onClick={() => handleOpen(index)}
                />
              ))}
              {review.photos.length > 3 && (
                <Typography
                  variant="body2"
                  color="primary"
                  sx={{ alignSelf: 'center', cursor: 'pointer' }}
                  onClick={() => handleOpen(0)}
                >
                  +{review.photos.length - 3} more
                </Typography>
              )}
            </Box>
          )}
          {review.isReported && (
            <Typography variant="caption" color="error" sx={{ mt: 1 }}>
              This review has been reported.
            </Typography>
          )}
        </Box>
      </Box>

      {/* Use the PhotoModal component */}
      <PhotoModal
        open={modalOpen}
        onClose={handleClose}
        photos={review.photos}
        initialIndex={currentIndex}
      />

      {/* Report Dialog */}
      <Dialog
        open={reportDialogOpen}
        onClose={handleReportDialogClose}
        PaperProps={{
          sx: {
            borderRadius: '12px',
            maxWidth: 500,
            width: '100%',
          },
        }}
      >
        <DialogTitle sx={{ fontWeight: 'bold', fontSize: '1.5rem', mb: 1, px: 3 }}>
          Report Review
        </DialogTitle>
        <DialogContent sx={{ pb: 1, px: 3 }}>
          <Typography sx={{ mb: 1, fontSize: '1.1rem' }}>
            Why do you want to report this review?
          </Typography>
          <TextField
            margin="dense"
            label="Reason"
            type="text"
            fullWidth
            variant="outlined"
            value={reportReason}
            onChange={handleReportReasonChange}
            error={profanityError || !!reportError}
            helperText={
              profanityError
                ? 'Please avoid using profanity.'
                : reportError
                ? reportError
                : ''
            }
            disabled={reporting}
          />
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button
            onClick={handleReportDialogClose}
            variant="outlined"
            sx={{
              borderRadius: '20px',
              textTransform: 'none',
              fontSize: '1.1rem',
              paddingX: 3,
            }}
            disabled={reporting}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmReport}
            variant="contained"
            color="primary"
            sx={{
              borderRadius: '20px',
              textTransform: 'none',
              fontSize: '1.1rem',
              paddingX: 3,
            }}
            disabled={!reportReason.trim() || profanityError || reporting}
          >
            {reporting ? 'Reporting...' : 'Report'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Custom Login Dialog */}
      <Dialog
        open={loginDialogOpen}
        onClose={handleLoginDialogClose}
        PaperProps={{
          sx: {
            borderRadius: '12px',
            maxWidth: 400,
            width: '90%',
            padding: 1,
          },
        }}
      >
        <DialogTitle sx={{ 
          fontWeight: 'bold', 
          fontSize: '1.5rem', 
          // center the title
          display: 'flex',
          justifyContent: 'center',
          mb: 1 }}>
          Login Required
        </DialogTitle>
        <DialogContent sx={{ pb: 1 }}>
          <Typography sx={{ mb: 2, fontSize: '1.1rem'           ,display: 'flex',
          justifyContent: 'center',}}>
            Please log in to report a review.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
          <Button
            onClick={handleLoginDialogClose}
            variant="outlined"
            sx={{
              borderRadius: '20px',
              textTransform: 'none',
              fontSize: '1.1rem',
              paddingX: 3,
              marginRight: 2,
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              borderRadius: '20px',
              textTransform: 'none',
              fontSize: '1.1rem',
              paddingX: 3,
            }}
            onClick={() => {
              handleLoginDialogClose();
              // Redirect to login page if applicable
              // For example, using react-router:
              // navigate('/login');
              window.location.href = '/login'; // Simple redirect
            }}
          >
            Log In
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="Review reported successfully!"
      />
    </>
  );
};

export default ReviewCard;
