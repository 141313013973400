// src/pages/Dashboard/MailTab.js
import React from 'react';
import Mailbox from '../../components/Mailbox';

const MailTab = () => {
  return (
    <div style={{ flex: 1, padding: '20px', background: '#F2F2F2' }}>
      <Mailbox />
    </div>
  );
};

export default MailTab;
