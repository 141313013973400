import React, { useState, useContext, useEffect } from 'react';
import Header from '../components/Header';
import { Avatar } from '@mui/material';
import { AuthContext } from '../components/AuthContext';
import userServices from "../services/user";
import { useTheme } from '@mui/material/styles';

// Material-UI Icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import MailIcon from '@mui/icons-material/Mail';
import SettingsIcon from '@mui/icons-material/Settings';

// Tab Components
import DashboardTab from './tabs/DashboardTab';
import ProfileTab from './tabs/ProfileTab';
import MailTab from './tabs/MailTab';
import AccountTab from './tabs/AccountTab';

const { userDetail } = userServices; // Destructure userDetail

const Dashboard = () => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState('Dashboard');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const iconStyle = (tabName) => ({
    color: activeTab === tabName ? '#fff' : '#000',
    marginRight: '10px',
  });

  const { userUUID, isAuthenticated } = useContext(AuthContext);
  const [user, setUser] = useState({});

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (userUUID && isAuthenticated) {
        try {
          const detail = await userDetail(userUUID);
          setUser(detail);
        } catch (error) {
          console.error("Error fetching user detail:", error);
        }
      } else {
        setUser({});
      }
    };

    fetchUserDetails();
  }, [userUUID, isAuthenticated]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', fontFamily: 'Arial, sans-serif' }}>
      <Header showSearchBar={false} />
      <div style={{ display: 'flex', minHeight: '100vh' }}>
        <div style={{
          width: '250px',
          backgroundColor: '#F2F2F2',
          padding: '20px',
          borderRight: '1px solid #ddd'
        }}>
          <nav>
            <div style={{
              width: '200px',
              height: '85vh',
              backgroundColor: '#ffffff',
              padding: '20px',
              borderRight: '1px solid #ddd',
              borderLeft: '1px solid #ddd',
              borderTop: '1px solid #ddd',
              borderBottom: '1px solid #ddd'
            }}>
              <ul style={{ listStyle: 'none', padding: 0 }}>
                <li
                  style={{
                    marginBottom: '20px',
                    fontWeight: activeTab === 'Dashboard' ? 'bold' : 'normal',
                    color: activeTab === 'Dashboard' ? '#fff' : '#000',
                    backgroundColor: activeTab === 'Dashboard' ? theme.palette.primary.main : 'transparent',
                    padding: '10px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  onClick={() => handleTabClick('Dashboard')}
                >
                  <DashboardIcon sx={iconStyle('Dashboard')} />
                  Dashboard
                </li>
                <li
                  style={{
                    marginBottom: '20px',
                    fontWeight: activeTab === 'Profile' ? 'bold' : 'normal',
                    color: activeTab === 'Profile' ? '#fff' : '#000',
                    backgroundColor: activeTab === 'Profile' ? theme.palette.primary.main : 'transparent',
                    padding: '10px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  onClick={() => handleTabClick('Profile')}
                >
                  <PersonIcon sx={iconStyle('Profile')} />
                  Profile
                </li>
                <li
                  style={{
                    marginBottom: '20px',
                    fontWeight: activeTab === 'Mail' ? 'bold' : 'normal',
                    color: activeTab === 'Mail' ? '#fff' : '#000',
                    backgroundColor: activeTab === 'Mail' ? theme.palette.primary.main : 'transparent',
                    padding: '10px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  onClick={() => handleTabClick('Mail')}
                >
                  <MailIcon sx={iconStyle('Mail')} />
                  Mail
                </li>
                <li
                  style={{
                    fontWeight: activeTab === 'Account' ? 'bold' : 'normal',
                    color: activeTab === 'Account' ? '#fff' : '#000',
                    backgroundColor: activeTab === 'Account' ? theme.palette.primary.main : 'transparent',
                    padding: '10px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  onClick={() => handleTabClick('Account')}
                >
                  <SettingsIcon sx={iconStyle('Account')} />
                  Account
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <div style={{ flex: 1, padding: '20px', background: '#F2F2F2' }}>
          {activeTab === 'Dashboard' && <DashboardTab theme={theme} />}
          {activeTab === 'Profile' && <ProfileTab theme={theme} user={user} />}
          {activeTab === 'Mail' && <MailTab />}
          {activeTab === 'Account' && <AccountTab theme={theme} user={user} />}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
