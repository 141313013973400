//import { Form, Link } from "react-router-dom"
import {
    Card, Container, CardContent, Box, TextField, Button, Input, Typography, Select, MenuItem
} from '@mui/material';
import Nav from "../Nav"
//import "../styles/Regipage.css"
import React, {useState, useEffect, useRef} from 'react';
import Footer from '../components/Footer';
import {useLocation, useNavigate} from 'react-router-dom';

import businessServices from '../services/business'
import categoryGet from "../services/category"
import SwalFire from '../components/SwalFire';
import {green} from '@mui/material/colors';
import {styled} from "@mui/system";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import DropBox from '../components/DropBox';
//import ico from '../assets/ico.png';

const {businessSignUp} = businessServices

const RegiPage2 = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [preview, setPreview] = useState(''); // Store image preview URL
    const [image, setImage] = useState(null); // Store image file
    const [verDocument, setVerificationDoc] = useState(null); // Store image file
    const [docPreview, setDocPreview] = useState(''); // Store image preview URL
    const [categories, setCategories] = useState([]); // Store image file
    const [selectedCategory, setSelectedCategory] = useState('');
    const [userEmail, setUserEmail] = useState(location.state?.userEmail)
    const [zip, setZip] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [address, setAddress] = useState("")
    const [country, setCountry] = useState("")
    const [contact, setContact] = useState("")
    const [description, setDescription] = useState("")
    const [isDragOver, setIsDragOver] = useState(false);
    const [photos, setPhotos] = useState([]);

    const fileInputRef = useRef(null);
    const fileInputDocRef = useRef(null);

    const [errors, setErrors] = useState({
        rating: false,
        profanity: false,
        photos: false,
    });

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
            const imagePreviewUrl = URL.createObjectURL(file);
            setPreview(imagePreviewUrl);
        }
    };

    const handleDocUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setVerificationDoc(file);
            const docPreviewUrl = URL.createObjectURL(file);
            setDocPreview(docPreviewUrl);
        }
    };

    const handleChange = (e) => {
        setSelectedCategory(e.target.value)
    }

    const handleStateChange = (e) => {
        setState(e.target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const formData = new FormData();
        formData.append('heroImage', image);
        formData.append('verDocument', verDocument);
        formData.append('userEmail', userEmail);
        formData.append('address', address);
        formData.append('city', city);
        formData.append('state', state);
        formData.append('zip', zip);
        formData.append('country', country);
        formData.append('contact', contact);
        formData.append('description', description);
        formData.append('categoryId', selectedCategory);
        const res = await businessSignUp(formData)
        if (res) {
            console.log("Success", res)
            SwalFire({title: "", text: "Business Signup success", background: "green", icon: "success"})
            navigate('/login/')
        } else {
            console.log("Fail")
            SwalFire({title: "", text: "Business Signup failed", background: "red", icon: "error"})
            navigate('/regipage_2/')
        }

    }

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = () => {
        setIsDragOver(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragOver(false);

        const files = Array.from(e.dataTransfer.files);
        if (files.length > 0) {
            const file = files[0];
            setImage(file);

            const imagePreviewUrl = URL.createObjectURL(file);
            setPreview(imagePreviewUrl);
        }
    };

    const handleDocDrop = (e) => {
        e.preventDefault();
        setIsDragOver(false);

        const files = Array.from(e.dataTransfer.files);
        if (files.length > 0) {
            const file = files[0];
            setVerificationDoc(file);

            const filePreviewUrl = URL.createObjectURL(file);
            setDocPreview(filePreviewUrl);
        }
    };

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleDocUploadClick = () => {
        fileInputDocRef.current.click()
    }


    useEffect(() => {
        const getCategories = async () => {
            const res = await categoryGet()
            setCategories(res)
        }
        getCategories()
    }, [])

    return (
        <div>
            <Nav/>
            <br/>
            <div style={{height: "6rem"}}></div>
            <Container maxWidth="sm">
                <Card sx={{width: '100%', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: "10px"}}>
                    <CardContent sx={{textAlign: "left"}}>
                        <h1>Welcome to <span style={{color: "#1F4A31"}}>LIAN</span></h1>
                        <div>
                            <Box onSubmit={handleSubmit} component="form"
                                 sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                                <p style={{fontSize: "0.9rem", marginBottom: 0, color: "#1D407B"}}>Business Hero
                                    Image</p>


                                <DropBox
                                    isDragOver={isDragOver}
                                    onDragOver={handleDragOver}
                                    onDragLeave={handleDragLeave}
                                    onDrop={handleDrop}
                                    onClick={handleUploadClick}
                                />
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{display: 'none'}} // Keeps it hidden
                                    onChange={handleFileUpload} // Optional handler for file selection
                                />

                                {preview && (
                                    <img
                                        src={preview}
                                        alt="Selected Preview"
                                        style={{
                                            marginTop: '10px',
                                            width: '200px',
                                            height: 'auto',
                                            border: '1px solid #ccc',
                                        }}
                                    />
                                )}

                                <p style={{fontSize: "0.9rem", marginBottom: 0, color: "#1D407B"}}>Address</p>
                                <TextField
                                    placeholder="Address"
                                    type="text" //  Company's name
                                    variant="outlined"
                                    required
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                                <p style={{fontSize: "0.9rem", marginBottom: 0, color: "#1D407B"}}>City</p>
                                <TextField
                                    placeholder="City"
                                    type="text" //   Owner's Name
                                    variant="outlined"
                                    onChange={(e) => setCity(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1).toLowerCase())}
                                    required
                                />
                                <p style={{fontSize: "0.9rem", marginBottom: 0, color: "#1D407B"}}>State</p>
                                <Select
                                    labelId="category-select-label"
                                    id="category-select"
                                    value={state}
                                    label="Category"
                                    onChange={handleStateChange}
                                >
                                    {/* Dynamically render menu items from categories */}
                                    <MenuItem value="AL">AL</MenuItem>
                                    <MenuItem value="AK">AK</MenuItem>
                                    <MenuItem value="AZ">AZ</MenuItem>
                                    <MenuItem value="AR">AR</MenuItem>
                                    <MenuItem value="CA">CA</MenuItem>
                                    <MenuItem value="CO">CO</MenuItem>
                                    <MenuItem value="CT">CT</MenuItem>
                                    <MenuItem value="DE">DE</MenuItem>
                                    <MenuItem value="FL">FL</MenuItem>
                                    <MenuItem value="GA">GA</MenuItem>
                                    <MenuItem value="HI">HI</MenuItem>
                                    <MenuItem value="ID">ID</MenuItem>
                                    <MenuItem value="IL">IL</MenuItem>
                                    <MenuItem value="IN">IN</MenuItem>
                                    <MenuItem value="IA">IA</MenuItem>
                                    <MenuItem value="KS">KS</MenuItem>
                                    <MenuItem value="KY">KY</MenuItem>
                                    <MenuItem value="LA">LA</MenuItem>
                                    <MenuItem value="ME">ME</MenuItem>
                                    <MenuItem value="MD">MD</MenuItem>
                                    <MenuItem value="MA">MA</MenuItem>
                                    <MenuItem value="MI">MI</MenuItem>
                                    <MenuItem value="MN">MN</MenuItem>
                                    <MenuItem value="MS">MS</MenuItem>
                                    <MenuItem value="MO">MO</MenuItem>
                                    <MenuItem value="MT">MT</MenuItem>
                                    <MenuItem value="NE">NE</MenuItem>
                                    <MenuItem value="NV">NV</MenuItem>
                                    <MenuItem value="NH">NH</MenuItem>
                                    <MenuItem value="NJ">NJ</MenuItem>
                                    <MenuItem value="NM">NM</MenuItem>
                                    <MenuItem value="NY">NY</MenuItem>
                                    <MenuItem value="NC">NC</MenuItem>
                                    <MenuItem value="ND">ND</MenuItem>
                                    <MenuItem value="OH">OH</MenuItem>
                                    <MenuItem value="OK">OK</MenuItem>
                                    <MenuItem value="OR">OR</MenuItem>
                                    <MenuItem value="PA">PA</MenuItem>
                                    <MenuItem value="RI">RI</MenuItem>
                                    <MenuItem value="SC">SC</MenuItem>
                                    <MenuItem value="SD">SD</MenuItem>
                                    <MenuItem value="TN">TN</MenuItem>
                                    <MenuItem value="TX">TX</MenuItem>
                                    <MenuItem value="UT">UT</MenuItem>
                                    <MenuItem value="VT">VT</MenuItem>
                                    <MenuItem value="VA">VA</MenuItem>
                                    <MenuItem value="WA">WA</MenuItem>
                                    <MenuItem value="WV">WV</MenuItem>
                                    <MenuItem value="WI">WI</MenuItem>
                                    <MenuItem value="WY">WY</MenuItem>

                                </Select>
                                <p style={{fontSize: "0.9rem", marginBottom: 0, color: "#1D407B"}}>Zip Code</p>
                                <TextField
                                    placeholder="Zip Code"
                                    type="text" // NOT DEFINED YET
                                    variant="outlined"
                                    onChange={(e) => setZip(e.target.value)}
                                    required
                                />
                                <p style={{fontSize: "0.9rem", marginBottom: 0, color: "#1D407B"}}>Country</p>
                                <TextField
                                    placeholder="Country"
                                    type="text" // NOT DEFINED YET
                                    variant="outlined"
                                    onChange={(e) => setCountry(e.target.value)}
                                    required
                                />
                                <p style={{fontSize: "0.9rem", marginBottom: 0, color: "#1D407B"}}>Contact Number</p>
                                <TextField
                                    placeholder="Contact Number"
                                    type="text" // NOT DEFINED YET
                                    variant="outlined"
                                    onChange={(e) => setContact(e.target.value)}
                                    required
                                />
                                <p style={{fontSize: "0.9rem", marginBottom: 0, color: "#1D407B"}}>Description</p>
                                <TextField
                                    multiline
                                    placeholder='Description'
                                    onChange={(e) => setDescription(e.target.value)}
                                    rows={4}
                                >
                                </TextField>

                                <p style={{fontSize: "0.9rem", marginBottom: 0, color: "#1D407B"}}>Business Verification
                                    Document</p>
                                <DropBox
                                    isDragOver={isDragOver}
                                    onDragOver={handleDragOver}
                                    onDragLeave={handleDragLeave}
                                    onDrop={handleDocDrop}
                                    onClick={handleDocUploadClick}
                                />
                                <input
                                    type="file"
                                    accept="pdf/*"
                                    ref={fileInputDocRef}
                                    style={{display: 'none'}} // Keeps it hidden
                                    onChange={handleDocUpload} // Optional handler for file selection
                                />
                                {docPreview && (
                                    <a rel="noreferrer" href={docPreview} target="_blank">View Doc</a>
                                )}

                                <p style={{fontSize: "0.9rem", marginBottom: 0, color: "#1D407B"}}>Category</p>

                                <Select
                                    labelId="category-select-label"
                                    id="category-select"
                                    value={selectedCategory}
                                    label="Category"
                                    onChange={handleChange}
                                >
                                    {/* Dynamically render menu items from categories */}
                                    {categories?.map((category, index) => (
                                        <MenuItem key={index} value={category.id}>
                                            {category.name}
                                        </MenuItem>
                                    ))}
                                </Select>


                                <div style={{textAlign: "center", marginBottom: "20px"}}>
                                    <Button type='submit' style={{
                                        width:
                                            "60%", backgroundColor: "#1F4A31",
                                        color: "white", borderRadius: "5px",
                                        padding: "10px", fontWeight: "500", fontSize: "1rem"
                                    }}>Submit</Button>
                                </div>
                            </Box>
                        </div>
                    </CardContent>
                </Card>
            </Container>
            <br/><br/>
            <Footer/>
        </div>
    )
}

export default RegiPage2