import React from 'react';
import '../styles/NotificationPanel.css';
import { Avatar } from '@mui/material';

const NotificationPanel = ({ notifications, setNotifications, panelRef }) => {
  const handleMarkAllRead = () => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) => ({
        ...notification,
        isRead: true,
      }))
    );
  };

  return (
    <div className="notification-panel" ref={panelRef}>
      <div className="notification-header">
        <h3>Notifications</h3>
        <button className="mark-read-btn" onClick={handleMarkAllRead}>
          ✓ Mark all Read
        </button>
      </div>

      <div className="notification-list scrollable-container">
        {notifications.map((notification, index) => (
          <div
            key={index} // Consider using a unique ID if available
            className={`notification-item ${notification.isRead ? '' : 'unread'}`}
          >
            <div className="notification-avatar" style={{ marginRight: '15px' }}>
              <Avatar
                sx={{
                  bgcolor: '#1F4A31', // Removed duplicate 'bgcolor'
                  width: 40,
                  height: 40,
                  fontSize: '1.2rem',
                }}
              >
                {notification.user.charAt(0).toUpperCase()}
              </Avatar>
            </div>
            <div className="notification-content">
              <p>
                <span className="notification-user">{notification.user}</span> {notification.message}
              </p>
              <span className="notification-time">{notification.time}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotificationPanel;
