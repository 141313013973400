import React from 'react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';

import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext';
import Regipage from './pages/Regipage';
import Home from "./pages/Home";
import Login from "./pages/Login";
import SignUpUser from './pages/SignUpUser';
import LoginRedirect from './pages/LoginRedirect'
import EmailVerify from "./pages/EmailVerify"
import NotFound from "./pages/NotFound"
import theme from './theme'; 
import Explore from './pages/Explore'; 
import { ThemeProvider, CssBaseline } from '@mui/material';
import Business from './pages/Business';
import ProtectedRoute from "./components/ProtectedRoute";
import './App.css';
import RegiPage2 from './pages/RegiPage2';
import Dashboard from './pages/Dashboard';
import AboutUs from './pages/AboutUs';

// Import LocationProvider
import { LocationProvider } from './services/LocationContext';
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
      <LocationProvider> {/* Wrap in LocationProvider */}
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/login" element={<ProtectedRoute><Login /></ProtectedRoute>} />
            <Route path="/explore/:category?" element={<Explore />} />
              <Route path="/signup-business/" element={<ProtectedRoute><Regipage/></ProtectedRoute>} />
            <Route path="/regipage_2" element={<ProtectedRoute><RegiPage2/></ProtectedRoute>} />
            <Route path="/business/:id" element={<Business />} />
            <Route path="/signup-user" element={<ProtectedRoute><SignUpUser /></ProtectedRoute>} />
            <Route path="/login/redirect" element={<ProtectedRoute><LoginRedirect /></ProtectedRoute>} />
            <Route path="/email-verify/:id/:key/" element={<EmailVerify />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
        </div>
      </LocationProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
