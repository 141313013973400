import React, { useState } from 'react';
import { Avatar, Button, Box, Typography, Divider } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import RateReviewIcon from '@mui/icons-material/RateReview';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import yummyDumplings from "../../assets/yummyDumplings.png";

const ProfileTab = ({ theme, user }) => {
  const [activeOption, setActiveOption] = useState("profile");

  const handleOptionClick = (option) => {
    setActiveOption(option);
  };

  return (
    <Box sx={{ padding: '20px', backgroundColor: '#F2F2F2', minHeight: '100%' }}>
      {/* Greeting */}
      <Box sx={{ marginBottom: '20px' }}>
        <Typography variant="h4" sx={{ textAlign: 'left', marginBottom: '-5px', fontWeight: 'bold' }}>
          Good Morning, {user.fullName || "User"}!
        </Typography>
        <Typography variant="h6" sx={{ textAlign: 'left', fontWeight: 'normal' }}>
          Welcome Back
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', gap: '20px' }}>
        {/* Left Column (Profile Card) */}
        <Box
          sx={{
            width: '300px',
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px'
          }}
        >
          {/* Image */}
          <Box sx={{ marginBottom: '20px' }}>
            <Avatar
              src={yummyDumplings}
              alt="Profile"
              sx={{ width: 120, height: 120, borderRadius: '50%' }}
            />
          </Box>

          {/* Title */}
          <Typography variant="h5" sx={{ marginBottom: '10px', fontWeight: 'bold', textAlign: 'center' }}>
            {user.fullName || "User"}
          </Typography>

          {/* Edit Picture Button */}
          <Button
            variant="outlined"
            startIcon={<EditIcon />}
            sx={{
              marginBottom: '20px',
              borderRadius: '20px',
              borderColor: theme.palette.primary.main,
              color: theme.palette.primary.main,
              textTransform: 'none'
            }}
          >
            Edit Picture
          </Button>

          {/* Options */}
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px',
                borderRadius: '5px',
                backgroundColor: activeOption === "profile" ? theme.palette.primary.main : '#f9f9f9',
                color: activeOption === "profile" ? '#fff' : '#333',
                cursor: 'pointer'
              }}
              onClick={() => handleOptionClick("profile")}
            >
              <PersonIcon sx={{ marginRight: '10px' }} />
              <Typography>Profile Overview</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px',
                borderRadius: '5px',
                backgroundColor: activeOption === "reviews" ? theme.palette.primary.main : '#f9f9f9',
                color: activeOption === "reviews" ? '#fff' : '#333',
                cursor: 'pointer'
              }}
              onClick={() => handleOptionClick("reviews")}
            >
              <RateReviewIcon sx={{ marginRight: '10px' }} />
              <Typography>Reviews</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px',
                borderRadius: '5px',
                backgroundColor: activeOption === "saved" ? theme.palette.primary.main : '#f9f9f9',
                color: activeOption === "saved" ? '#fff' : '#333',
                cursor: 'pointer'
              }}
              onClick={() => handleOptionClick("saved")}
            >
              <BookmarkIcon sx={{ marginRight: '10px' }} />
              <Typography>Saved</Typography>
            </Box>
          </Box>
        </Box>

        {/* Right Column (Profile Overview) */}
        <Box sx={{ flex: 1, backgroundColor: '#ffffff', borderRadius: '10px', padding: '20px', boxShadow: '0 4px 8px rgba(0,0,0,0.2)' }}>
          <Typography
            variant="h4"
            sx={{
              marginBottom: '20px',
              fontWeight: 'bold',
              color: theme.palette.primary.main
            }}
          >
            Profile Overview
          </Typography>
          <Box>
            {/* Basic Details Header */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
                Basic Details
              </Typography>
              <Box sx={{ display: 'flex', gap: '20px' }}>
                <EditIcon sx={{ cursor: 'pointer', color: '#666' }} />
              </Box>
            </Box>
            <Divider sx={{ borderBottomWidth: 2, borderColor: theme.palette.primary.main, width: '125px', marginBottom: '20px' }} />

            {/* User Info Rows */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
              <Box sx={{ textAlign: 'left' }}>
                <Typography variant="subtitle1" sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
                  First Name
                </Typography>
                <Typography variant="body2" color="#777">{user.firstName || "N/A"}</Typography>
              </Box>
              <Box sx={{ textAlign: 'left' }}>
                <Typography variant="subtitle1" sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
                  Last Name
                </Typography>
                <Typography variant="body2" color="#777">{user.lastName || "N/A"}</Typography>
              </Box>
              <Box sx={{ textAlign: 'left' }}>
                <Typography variant="subtitle1" sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
                  Contact Number
                </Typography>
                <Typography variant="body2" color="#777">{user.contactNumber || "000-000-0000"}</Typography>
              </Box>
            </Box>

            {/* Add more rows of user info if needed */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileTab;
